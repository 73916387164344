import { React, useEffect } from 'react';
// import { Link } from "react-router-dom";
import './../styles/privacyPolicy.scss';
import Heading from '../components/Heading/Heading';
import ReactGA from 'react-ga';
const Partnership = () => {
   useEffect(() => {
      ReactGA.pageview(window.location.pathname);
   });
   const top = {
      heading: 'Privacy Policy',
   };
   return (
      <>
         <div className='privacyPolicy'>
            {/* ---------------- heading container --------------- */}
            <div className='privacyHeading'>
               <h1>
                  <Heading heading={top.heading} />
               </h1>
            </div>
            <div>
               <svg
                  className='contact-us-fingerPrintRight-pri'
                  width='169'
                  height='511'
                  viewBox='0 0 169 511'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <g clipPath='url(#clip0_417_49529)'>
                     <path
                        d='M335.535 68.2919C336.837 67.8429 337.93 66.9317 338.607 65.7307C340.361 62.289 339.607 58.0999 336.763 55.486C295.229 16.9045 239.731 -3.00867 183.153 0.369198C135.262 4.29672 90.8843 27.0656 59.7522 63.6818C57.3935 66.6891 57.3935 70.9192 59.7522 73.9266C60.7204 75.3839 62.3105 76.3086 64.0556 76.429C65.8007 76.5494 67.5026 75.8519 68.6617 74.5412C98.0219 40.1001 139.802 18.6676 184.894 14.9168C238.238 11.7705 290.551 30.5719 329.697 66.9601C331.227 68.4875 333.495 69.005 335.535 68.2919Z'
                        fill='#F5CDC1'
                     />
                     <path
                        d='M11.5187 279.334C14.53 278.073 16.2107 274.84 15.5127 271.65C6.57802 212.817 18.3421 152.706 48.795 101.587C50.5992 98.1896 49.9302 94.0069 47.1564 91.3422C45.9385 90.1119 44.223 89.51 42.5037 89.71C40.7845 89.91 39.2525 90.8896 38.3494 92.3667C6.04313 146.632 -6.40963 210.442 3.12143 272.879C3.33644 276.696 6.46855 279.696 10.2899 279.743L11.5187 279.334Z'
                        fill='#F5CDC1'
                     />
                     <path
                        d='M23.6033 332.812C26.9858 331.113 28.5001 327.103 27.0851 323.591C21.6183 306.664 17.677 289.281 15.3083 271.65C15.0932 267.833 11.9611 264.834 8.13979 264.786C6.3646 265.081 4.78874 266.093 3.78164 267.585C2.77455 269.077 2.42474 270.917 2.81455 272.675C5.39823 291.355 9.64811 309.767 15.5131 327.689C15.9042 329.443 16.9774 330.969 18.4953 331.931C20.0133 332.892 21.8513 333.209 23.6033 332.812Z'
                        fill='#F5CDC1'
                     />
                     <path
                        d='M182.027 333.017C185.515 331.471 187.147 327.435 185.714 323.899C179.264 302.699 174.538 281.012 171.582 259.049V256.078C170.177 243.164 173.211 230.156 180.184 219.197C186.917 208.488 198.301 201.579 210.906 200.552C231.042 200.215 249.428 211.953 257.604 230.364C258.241 232.023 259.565 233.324 261.234 233.933C262.903 234.542 264.753 234.399 266.308 233.54C269.485 231.128 270.325 226.705 268.254 223.295C257.934 199.825 234.482 184.898 208.858 185.492C193.013 186.873 178.745 195.642 170.353 209.157C160.954 224.203 157.317 242.144 160.112 259.664L160.317 266.221C163.448 287.024 168.103 307.569 174.245 327.689C175.062 331.349 178.664 333.676 182.335 332.914L182.027 333.017Z'
                        fill='#F5CDC1'
                     />
                     <path
                        d='M254.327 510.046C255.395 509.689 256.325 509.009 256.989 508.099C258.947 504.848 258.574 500.704 256.068 497.855C195.549 432.333 156.928 349.583 145.57 261.098V258.332C142.66 235.743 148.525 212.898 161.956 194.507C164.038 191.317 163.745 187.131 161.239 184.262C160.15 182.919 158.513 182.138 156.784 182.138C155.055 182.138 153.418 182.919 152.329 184.262C135.635 206.889 128.701 235.265 133.077 263.045L133.896 268.987C146.748 358.76 186.569 442.506 248.08 509.124C249.802 510.629 252.244 510.989 254.327 510.046Z'
                        fill='#F5CDC1'
                     />
                     <path
                        d='M156.527 411.798H157.449C160.811 409.539 161.842 405.055 159.804 401.553C139.396 357.768 125.756 311.134 119.353 263.249V260.995C111.775 197.683 148.437 143.078 201.279 138.673C254.121 134.268 303.686 181.086 313.927 243.374V247.37C316.18 263.966 320.516 280.213 326.83 295.725C327.339 297.452 328.547 298.889 330.161 299.686C331.775 300.484 333.649 300.571 335.33 299.925C338.582 297.899 339.776 293.74 338.095 290.295C332.923 277.54 329.213 264.239 327.035 250.648L326.523 246.345C316.794 173.915 259.344 119.003 198.514 123.408C137.684 127.813 96.1068 191.228 105.835 263.761L106.45 268.371C113.241 316.399 127.042 363.17 147.413 407.188C147.859 409.109 149.133 410.734 150.893 411.624C152.652 412.514 154.716 412.577 156.527 411.798Z'
                        fill='#F5CDC1'
                     />
                     <path
                        d='M217.665 509.534C218.576 509.247 219.391 508.716 220.021 507.998C222.379 504.99 222.379 500.76 220.021 497.753C204.258 478.484 190.144 457.922 177.829 436.284C177.113 434.668 175.737 433.438 174.051 432.908C172.365 432.378 170.532 432.601 169.022 433.518C166.106 436.115 165.46 440.425 167.486 443.763C180.428 466.089 195.154 487.331 211.521 507.28C212.95 509.172 215.352 510.054 217.665 509.534Z'
                        fill='#F5CDC1'
                     />
                     <path
                        d='M176.907 500.108C177.66 499.877 178.357 499.494 178.955 498.981C181.432 496.079 181.642 491.871 179.467 488.737C133.871 422.644 104.504 346.723 93.7521 267.143V265.503C90.7634 244.707 91.7714 223.531 96.722 203.113C97.6752 199.269 95.8491 195.275 92.3184 193.483C90.7208 192.798 88.8965 192.889 87.3752 193.73C85.8538 194.571 84.8058 196.068 84.5356 197.786C79.3677 219.415 78.1534 241.802 80.9513 263.864V266.528C91.9059 349.499 122.407 428.679 169.943 497.547C171.405 499.894 174.273 500.949 176.907 500.108Z'
                        fill='#F5CDC1'
                     />
                     <path
                        d='M105.631 172.584C107.047 172.077 108.218 171.052 108.908 169.716C129.532 124.868 178.136 99.9426 226.574 109.374C228.313 109.624 230.073 109.077 231.364 107.885C232.655 106.692 233.341 104.982 233.23 103.227C233.619 99.3476 231.152 95.7542 227.393 94.7239C216.91 92.6649 206.19 92.0787 195.544 92.9823C153.351 97.08 116.354 122.907 97.9506 161.11C96.2789 164.659 97.2519 168.892 100.306 171.355C101.74 172.675 103.763 173.142 105.631 172.584Z'
                        fill='#F5CDC1'
                     />
                     <path
                        d='M67.3313 299.516C70.4256 298.127 72.0688 294.709 71.2227 291.423C69.9938 284.661 68.9698 277.797 67.9457 270.933V267.553C54.6328 169.101 111.162 83.6592 193.804 76.8977C225.624 74.9678 257.152 83.9279 283.205 102.305C311.47 121.806 333.967 148.559 348.336 179.755C348.92 181.437 350.193 182.79 351.835 183.474C353.478 184.159 355.334 184.11 356.938 183.341C360.359 181.113 361.362 176.554 359.191 173.096C343.649 139.284 319.273 110.297 288.633 89.1914C260.255 69.3015 225.914 59.7546 191.346 62.1452C103.379 69.2141 42.7537 158.753 54.3257 262.942L55.0425 268.577C56.169 276.875 57.5001 285.174 58.9338 293.267C59.3095 297.082 62.58 299.95 66.4096 299.824L67.3313 299.516Z'
                        fill='#F5CDC1'
                     />
                     <path
                        d='M131.438 477.673L132.974 476.956C135.895 474.353 136.502 470.016 134.408 466.711C109.982 423.673 91.4841 377.529 79.4151 329.534C78.7865 325.838 75.347 323.302 71.6322 323.797C69.9087 324.377 68.4918 325.628 67.7016 327.267C66.9115 328.906 66.8149 330.794 67.4336 332.505C79.8044 382.098 98.8922 429.767 124.167 474.19C125.384 477.001 128.485 478.487 131.438 477.673Z'
                        fill='#F5CDC1'
                     />
                     <path
                        d='M430.159 296.341C431.735 295.855 433.012 294.69 433.641 293.165C435.163 289.497 433.978 285.264 430.774 282.92C417.439 273.076 408.561 258.335 406.094 241.941V239.585C398.603 181.21 371.651 127.068 329.596 85.9137C292.354 48.0245 240.585 28.0481 187.557 31.1042C166.597 32.7312 146.184 38.5858 127.546 48.3154C124.353 50.4434 123.254 54.6232 124.986 58.0479C125.539 59.7381 126.765 61.125 128.374 61.8813C129.983 62.6376 131.832 62.6962 133.486 62.0434C150.892 52.9642 169.938 47.4606 189.503 45.8567C239.187 42.9254 287.717 61.598 322.632 97.0805C361.008 134.695 385.915 183.918 393.498 237.126L394.01 241.019C396.663 262.802 408.187 282.522 425.858 295.521C427.123 296.364 428.674 296.659 430.159 296.341Z'
                        fill='#F5CDC1'
                     />
                     <path
                        d='M80.0296 432.903H80.7464C84.2647 430.776 85.4462 426.231 83.4092 422.659C63.3815 374.984 49.6125 324.914 42.4463 273.7L41.9343 269.295C31.6936 193.483 56.8856 120.541 107.577 79.0494C110.365 76.3389 110.797 72.0139 108.601 68.8047C107.849 67.2141 106.419 66.0478 104.71 65.6303C103.001 65.2129 101.195 65.5887 99.7942 66.6533C46.9521 109.886 20.0191 184.98 28.7237 263.865L29.543 270.319C36.8398 324.67 51.3739 377.799 72.7587 428.293C73.7643 431.375 76.8146 433.309 80.0296 432.903Z'
                        fill='#F5CDC1'
                     />
                  </g>
                  <defs>
                     <clipPath id='clip0_417_49529'>
                        <rect
                           width='434.299'
                           height='510.565'
                           fill='white'
                           transform='matrix(-1 0 0 1 434.299 0)'
                        />
                     </clipPath>
                  </defs>
               </svg>
            </div>
            <div className='privacyPolicyBox'>
               <h2>1. Privacy Policy</h2>
               <div className='privacyPolicyPara'>
                  We value your trust. In order to honour that trust, Innerscore adheres to ethical
                  standards in gathering, using and safeguarding any information you provide.{' '}
               </div>
               <div className='privacyPolicyParabottom'>
                  People Combine Basics Private Limited (operating under the brand name Innerscore),
                  is an EdTech company, incorporated in India, for imparting learning. This privacy
                  policy governs your use of the ‘Innerscore' ('Application') and the other
                  associated services managed by the Company. Please read this privacy policy
                  ('Policy') carefully before using the Application, along with the Terms of Use
                  ('ToU') provided on the Application. Your use of the Application or services in
                  connection with the Application, shall signify your acceptance of this Policy and
                  your agreement to be legally bound by the same. If you do not agree with the terms
                  of this Policy, do not use the Application or avail any of our Services.
               </div>

               <h2>2. User Provided Information</h2>
               <div className='privacyPolicyPara'>
                  The Application obtains the information you provide when you download and register
                  for the Application or Services. When you register with us, you generally provide{' '}
               </div>
               <div className='privacyPolicyParabottom'>
                  <div className='bullet-points'>
                     <div className='bullet'>&#8226;</div>
                     <p>your name / username, email address, phone number</p>
                  </div>

                  <div className='bullet-points'>
                     <div className='bullet'>&#8226;</div>
                     <p>information you provide us when you contact us for help</p>
                  </div>

                  <div className='bullet-points'>
                     <div className='bullet'>&#8226;</div>
                     <p>
                        {' '}
                        information you enter into our system when using the Application / Services
                        such as while asking doubts, participating in discussions and taking tests.
                        The said information collected from the users could be categorized as
                        “Personal Information”, “Sensitive Personal Information” and “Associated
                        Information”. Personal Information, Sensitive Personal Information and
                        Associated Information (each as individually defined under this Information
                        Technology (Reasonable security practices and procedures and sensitive
                        personal data or information) Rules, 2011 (the “Data Protection Rules”))
                        shall collectively be referred to as 'Information' in this Policy. We may
                        use the Information to contact you from time to time, to provide you with
                        the Services, important information, required notices and marketing
                        promotions. We will ask you when we need more information that personally
                        identifies you (personal information) or allows us to contact you. We will
                        not differentiate between who is using the device to access the Application
                        or Services, so long as the log in / access credentials match with yours. In
                        order to make the best use of the Application / Services and enable your
                        Information to be captured accurately on the Application / Services, it is
                        essential that you have logged in using your own credentials. We will always
                        provide the option to you to not provide the Personal Information or
                        Sensitive Personal Information, which we seek from you. Further, you shall,
                        at any time while using the Application / Services, also have an option to
                        withdraw your consent given earlier to us to use such Personal Information
                        or Sensitive Personal Information. Such withdrawal of the consent is
                        required to be sent in writing to us at the contact details provided in this
                        Policy below. In such event, however, the Company fully reserves the right
                        not to allow further usage of the Application or provide any Services
                        thereunder to you.
                     </p>
                  </div>
               </div>
               <h2>3. Automatically Collected Information</h2>
               <div className='privacyPolicyParabottom'>
                  {' '}
                  In addition, the Application / Services may collect certain information
                  automatically, including, but not limited to, the type of mobile device you use,
                  your mobile devices unique device ID, the IP address of your mobile device, your
                  mobile operating system, the type of mobile Internet browsers you use, and
                  information about the way you use the Application / Services. As is true of most
                  Mobile applications, we also collect other relevant information as per the
                  permissions that you provide.
               </div>

               <h2>4. Access to your Personal Information: </h2>
               <div className='privacyPolicyParabottom'>
                  {' '}
                  We will provide you with the means to ensure that your Personal Information is
                  correct and current. If you have filled out a user profile, we will provide an
                  obvious way for you to access and change your profile from our
                  Application/Services. We adopt reasonable security measures to protect your
                  password from being exposed or disclosed to anyone.
               </div>

               <h2>5. User Conduct</h2>
               <div className='privacyPolicyPara'>
                  You understand and agree that you are solely responsible for compliance with any
                  and all laws, rules, regulations that may apply to your use of the Application and
                  Services.{' '}
               </div>
               <div className='privacyPolicyParabottom'>
                  People Combine Basics Private Limited (operating under the brand name Innerscore),
                  is an EdTech company, incorporated in India, for imparting learning. This privacy
                  policy governs your use of the ‘Innerscore' ('Application') and the other
                  associated services managed by the Company. Please read this privacy policy
                  ('Policy') carefully before using the Application, along with the Terms of Use
                  ('ToU') provided on the Application. Your use of the Application or services in
                  connection with the Application, shall signify your acceptance of this Policy and
                  your agreement to be legally bound by the same. If you do not agree with the terms
                  of this Policy, do not use the Application or avail any of our Services.
               </div>
            </div>
            {/* <div> */}
            <svg
               className='contact-us-fingerPrintLeft-pri'
               width='218'
               height='262'
               viewBox='0 0 218 262'
               fill='none'
               xmlns='http://www.w3.org/2000/svg'>
               <g clipPath='url(#clip0_272_36327)'>
                  <path
                     d='M-118.236 68.2919C-119.539 67.8429 -120.632 66.9317 -121.308 65.7307C-123.062 62.289 -122.308 58.0999 -119.465 55.486C-77.9303 16.9045 -22.4321 -3.00867 34.1459 0.369198C82.0362 4.29672 126.414 27.0656 157.546 63.6818C159.905 66.6891 159.905 70.9192 157.546 73.9266C156.578 75.3839 154.988 76.3086 153.243 76.429C151.498 76.5494 149.796 75.8519 148.637 74.5412C119.277 40.1001 77.4962 18.6676 32.4049 14.9168C-20.9396 11.7705 -73.252 30.5719 -112.399 66.9601C-113.928 68.4875 -116.196 69.005 -118.236 68.2919Z'
                     fill='#F5CDC1'
                  />
                  <path
                     d='M205.78 279.334C202.769 278.073 201.088 274.84 201.786 271.65C210.721 212.817 198.957 152.706 168.504 101.587C166.7 98.1896 167.369 94.0069 170.142 91.3422C171.36 90.1119 173.076 89.51 174.795 89.71C176.514 89.91 178.046 90.8896 178.949 92.3667C211.256 146.632 223.708 210.442 214.177 272.879C213.962 276.696 210.83 279.696 207.009 279.743L205.78 279.334Z'
                     fill='#F5CDC1'
                  />
                  <path
                     d='M193.695 332.812C190.313 331.113 188.798 327.103 190.213 323.591C195.68 306.664 199.622 289.281 201.99 271.65C202.205 267.833 205.337 264.834 209.159 264.786C210.934 265.081 212.51 266.093 213.517 267.585C214.524 269.077 214.874 270.917 214.484 272.675C211.9 291.355 207.65 309.767 201.785 327.689C201.394 329.443 200.321 330.969 198.803 331.931C197.285 332.892 195.447 333.209 193.695 332.812Z'
                     fill='#F5CDC1'
                  />
                  <path
                     d='M-124.585 476.956C-126.102 476.466 -127.334 475.346 -127.965 473.882C-129.552 470.283 -128.498 466.066 -125.404 463.637C-59.1721 412.922 -16.2239 337.595 -6.30507 254.747C-6.03858 250.913 -2.875 247.927 0.965836 247.883C2.69196 248.292 4.17842 249.385 5.08548 250.91C5.99254 252.435 6.24283 254.263 5.7791 255.976C-4.76034 343.324 -50.0602 422.732 -119.875 476.238C-121.256 477.177 -122.987 477.44 -124.585 476.956Z'
                     fill='#F5CDC1'
                  />
                  <path
                     d='M-98.9837 492.63C-100.349 492.135 -101.481 491.149 -102.158 489.864C-103.802 486.351 -102.926 482.174 -100.008 479.62C-33.8181 422.889 8.86362 343.519 19.7059 257.001V254.85C21.3064 243.819 13.799 233.532 2.80891 231.696C-8.24969 231.757 -17.7057 239.669 -19.7207 250.547V253.825C-24.3969 288.776 -35.0307 322.665 -51.1597 354.019C-51.7684 355.701 -53.0908 357.027 -54.7708 357.64C-56.4509 358.253 -58.316 358.09 -59.8643 357.195C-63.0496 354.78 -63.8514 350.325 -61.7077 346.95C-47.661 319.509 -38.0519 290.014 -33.2385 259.562L-32.3168 252.493C-29.4494 231.184 -12.5523 215.1 5.26654 216.534C23.0854 217.969 35.3743 236.409 32.5069 257.513L31.5852 264.07C19.035 352.631 -25.4336 433.552 -93.4539 491.606C-95.0085 492.854 -97.0854 493.239 -98.9837 492.63Z'
                     fill='#F5CDC1'
                  />
                  <path
                     d='M35.2717 333.017C31.7842 331.471 30.1524 327.435 31.585 323.899C38.0351 302.699 42.7613 281.012 45.7173 259.049V256.078C47.122 243.164 44.0881 230.156 37.1151 219.197C30.3819 208.488 18.9977 201.579 6.39293 200.552C-13.7426 200.215 -32.129 211.953 -40.3048 230.364C-40.9421 232.023 -42.2659 233.324 -43.9348 233.933C-45.6036 234.542 -47.4542 234.399 -49.0094 233.54C-52.1857 231.128 -53.0257 226.705 -50.955 223.295C-40.6349 199.825 -17.183 184.898 8.44102 185.492C24.2857 186.873 38.5537 195.642 46.9461 209.157C56.3448 224.203 59.9825 242.144 57.1868 259.664L56.9819 266.221C53.8511 287.024 49.196 307.569 43.0545 327.689C42.2375 331.349 38.635 333.676 34.9643 332.914L35.2717 333.017Z'
                     fill='#F5CDC1'
                  />
                  <path
                     d='M-69.7979 504.002C-71.0013 503.532 -72.0359 502.711 -72.7677 501.646C-74.5626 498.264 -73.9394 494.107 -71.2318 491.401C-32.3917 454.731 -1.80952 410.202 18.4769 360.78C18.9848 359.079 20.1815 357.667 21.777 356.889C23.3726 356.11 25.2212 356.035 26.8744 356.682C30.1683 358.678 31.3727 362.873 29.6394 366.312C8.4728 417.929 -23.4344 464.446 -63.9609 502.773C-65.5449 504.201 -67.7726 504.67 -69.7979 504.002Z'
                     fill='#F5CDC1'
                  />
                  <path
                     d='M-181.524 411.696C-183.365 411.066 -184.78 409.573 -185.313 407.701C-186.656 403.819 -185.069 399.529 -181.524 397.456C-131.447 370.205 -94.7851 315.498 -85.0564 252.698L-84.4418 247.985C-77.0685 192.663 -33.2384 150.66 13.3569 154.451C21.1577 155.08 28.8029 156.984 35.9888 160.085C39.3 162.006 40.6262 166.118 39.0611 169.613C38.5645 171.337 37.3928 172.789 35.812 173.637C34.2311 174.486 32.3747 174.66 30.6636 174.12C24.5524 171.456 18.0514 169.795 11.4112 169.203C-27.1963 166.13 -63.6532 199.937 -71.4361 245.219V249.01C-80.8576 318.674 -121.206 380.655 -176.711 410.877C-178.107 411.868 -179.878 412.169 -181.524 411.696Z'
                     fill='#F5CDC1'
                  />
                  <path
                     d='M-37.0278 510.046C-38.0957 509.689 -39.0261 509.009 -39.6903 508.099C-41.6474 504.848 -41.2745 500.704 -38.7686 497.855C21.7505 432.333 60.371 349.583 71.7286 261.098V258.332C74.6388 235.743 68.7739 212.898 55.3434 194.507C53.2612 191.317 53.5541 187.131 56.0602 184.262C57.1491 182.919 58.7859 182.138 60.515 182.138C62.244 182.138 63.8808 182.919 64.9697 184.262C81.6638 206.889 88.5982 235.265 84.2223 263.045L83.403 268.987C70.5507 358.76 30.7299 442.506 -30.7809 509.124C-32.5029 510.629 -34.9448 510.989 -37.0278 510.046Z'
                     fill='#F5CDC1'
                  />
                  <path
                     d='M60.7719 411.798H59.8502C56.4874 409.539 55.4566 405.055 57.4948 401.553C77.9032 357.768 91.5426 311.134 97.9457 263.249V260.995C105.524 197.683 68.8622 143.078 16.0201 138.673C-36.822 134.268 -86.3872 181.086 -96.6279 243.374V247.37C-98.8815 263.966 -103.217 280.213 -109.531 295.725C-110.04 297.452 -111.248 298.889 -112.862 299.686C-114.476 300.484 -116.35 300.571 -118.031 299.925C-121.283 297.899 -122.477 293.74 -120.796 290.295C-115.624 277.54 -111.914 264.239 -109.736 250.648L-109.224 246.345C-99.4951 173.915 -42.0448 119.003 18.7851 123.408C79.6149 127.813 121.192 191.228 111.463 263.761L110.849 268.371C104.058 316.4 90.2568 363.17 69.886 407.188C69.4399 409.109 68.1655 410.734 66.406 411.624C64.6465 412.514 62.5831 412.577 60.7719 411.798Z'
                     fill='#F5CDC1'
                  />
                  <path
                     d='M-0.366321 509.534C-1.27712 509.247 -2.09182 508.716 -2.72174 507.998C-5.08038 504.99 -5.08038 500.76 -2.72174 497.753C13.0413 478.484 27.155 457.922 39.47 436.284C40.1854 434.668 41.562 433.438 43.248 432.908C44.9341 432.378 46.7664 432.601 48.277 433.518C51.1928 436.115 51.839 440.425 49.8132 443.763C36.871 466.089 22.1447 487.331 5.7782 507.28C4.3493 509.172 1.94643 510.054 -0.366321 509.534Z'
                     fill='#F5CDC1'
                  />
                  <path
                     d='M40.3921 500.108C39.6392 499.877 38.9421 499.494 38.344 498.981C35.8671 496.079 35.6569 491.871 37.832 488.737C83.4281 422.644 112.795 346.723 123.547 267.142V265.503C126.535 244.707 125.527 223.531 120.577 203.113C119.624 199.268 121.45 195.275 124.98 193.483C126.578 192.798 128.402 192.889 129.924 193.73C131.445 194.571 132.493 196.068 132.763 197.785C137.931 219.415 139.145 241.802 136.347 263.864V266.528C125.393 349.499 94.8915 428.679 47.3556 497.547C45.8934 499.894 43.0256 500.949 40.3921 500.108Z'
                     fill='#F5CDC1'
                  />
                  <path
                     d='M111.668 172.584C110.252 172.077 109.081 171.052 108.391 169.715C87.7665 124.868 39.1623 99.9425 -9.2753 109.374C-11.0147 109.624 -12.7741 109.077 -14.0653 107.885C-15.3566 106.692 -16.0422 104.981 -15.9318 103.227C-16.3202 99.3476 -13.8533 95.7541 -10.0946 94.7238C0.389055 92.6649 11.1087 92.0787 21.7542 92.9822C63.9472 97.08 100.944 122.907 119.348 161.11C121.02 164.659 120.047 168.892 116.993 171.355C115.558 172.675 113.535 173.142 111.668 172.584Z'
                     fill='#F5CDC1'
                  />
                  <path
                     d='M149.967 299.516C146.873 298.127 145.23 294.709 146.076 291.423C147.305 284.661 148.329 277.797 149.353 270.933V267.553C162.666 169.101 106.137 83.6592 23.4947 76.8977C-8.32477 74.9678 -39.8535 83.9279 -65.9066 102.305C-94.1715 121.806 -116.669 148.559 -131.037 179.755C-131.621 181.437 -132.894 182.79 -134.536 183.474C-136.179 184.159 -138.035 184.11 -139.64 183.341C-143.06 181.113 -144.063 176.554 -141.893 173.096C-126.351 139.284 -101.974 110.297 -71.3341 89.1914C-42.9558 69.3015 -8.61493 59.7546 25.9526 62.1452C113.92 69.2141 174.545 158.753 162.973 262.942L162.256 268.577C161.13 276.875 159.799 285.174 158.365 293.267C157.989 297.082 154.719 299.95 150.889 299.824L149.967 299.516Z'
                     fill='#F5CDC1'
                  />
                  <path
                     d='M85.8606 477.673L84.3246 476.956C81.4036 474.353 80.7968 470.016 82.8909 466.711C107.316 423.673 125.814 377.529 137.883 329.534C138.512 325.838 141.952 323.302 145.666 323.797C147.39 324.377 148.807 325.628 149.597 327.267C150.387 328.906 150.484 330.794 149.865 332.505C137.494 382.098 118.406 429.767 93.1315 474.19C91.9143 477.001 88.8132 478.487 85.8606 477.673Z'
                     fill='#F5CDC1'
                  />
                  <path
                     d='M-212.861 296.341C-214.436 295.855 -215.713 294.69 -216.342 293.165C-217.864 289.497 -216.679 285.265 -213.475 282.92C-200.14 273.076 -191.262 258.335 -188.795 241.941V239.585C-181.305 181.21 -154.353 127.068 -112.297 85.9137C-75.0556 48.0246 -23.2866 28.0481 29.742 31.1043C50.7015 32.7313 71.1149 38.5859 89.7524 48.3155C92.9456 50.4435 94.0452 54.6233 92.3127 58.048C91.7596 59.7381 90.5338 61.125 88.9249 61.8813C87.316 62.6376 85.4664 62.6963 83.8128 62.0435C66.4066 52.9643 47.3606 47.4607 27.7961 45.8567C-21.888 42.9255 -70.4177 61.5981 -105.333 97.0805C-143.709 134.695 -168.616 183.918 -176.199 237.126L-176.711 241.019C-179.364 262.802 -190.888 282.522 -208.559 295.521C-209.824 296.364 -211.375 296.659 -212.861 296.341Z'
                     fill='#F5CDC1'
                  />
                  <path
                     d='M137.269 432.903H136.553C133.034 430.776 131.853 426.231 133.89 422.659C153.918 374.984 167.687 324.914 174.853 273.7L175.365 269.295C185.605 193.483 160.414 120.541 109.722 79.0494C106.934 76.3388 106.502 72.0138 108.698 68.8046C109.451 67.214 110.88 66.0478 112.589 65.6303C114.298 65.2128 116.104 65.5886 117.505 66.6532C170.347 109.886 197.28 184.98 188.575 263.865L187.756 270.319C180.459 324.67 165.925 377.799 144.54 428.293C143.535 431.375 140.484 433.309 137.269 432.903Z'
                     fill='#F5CDC1'
                  />
               </g>
               <defs>
                  <clipPath id='clip0_272_36327'>
                     <rect
                        width='434.299'
                        height='510.565'
                        fill='white'
                        transform='translate(-217)'
                     />
                  </clipPath>
               </defs>
            </svg>

            {/* <img
            className="contact-us-fingerPrintLeft"
            src={fingerPrintLeft}
            alt=""
          /> */}
            {/* </div> */}
         </div>
      </>
   );
};

export default Partnership;
