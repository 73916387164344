import React from 'react';
import '../styles/partnership.scss';
import Heading from '../components/Heading/Heading';

import logo1 from '../assets/images/partnership/logo1.jpeg';
import logo2 from '../assets/images/partnership/logo2.jpeg';
// import logo3 from '../assets/images/partnership/logo3.png';
// import logo4 from '../assets/images/partnership/logo4.png';
import { Link } from 'react-router-dom';

const Partnership = () => {
   const top = {
      heading: 'Partnership',
      paragraph: 'Offer your expertise to Innerscore to impact how students learn.',
   };
   return (
      <>
         <div className='partnership'>
            {/* ---------------- heading container --------------- */}
            <div className='headingContainer'>
               <Heading heading={top.heading} paragraph={top.paragraph} />
            </div>

            {/* -------------------- partners logo ----------------- */}
            <div className='partnership-logo'>
               <div className='partnership-logo-box'>
                  <div className='partnership-logo-boxes'>
                     <img src={logo1} alt='pic' />
                  </div>

                  <div className='partnership-logo-boxes'>
                     <img src={logo2} alt='pic' />
                  </div>

                  {/* <div className='partnership-logo-boxes'>
                     <img src={logo4} alt='pic' />
                  </div> */}

                  {/* <div className='partnership-logo-boxes'>
                     <img src={logo3} alt='pic' />
                  </div> */}
               </div>
            </div>

            {/* -------------------- partners review -------------- */}
            {/* <div className='partners-review'>
               <div className='partners-review-heading'>What our partners are saying</div>
               <div className='partners-review-box'>
                  <div className='partners-review-boxes'>
                     <h4>
                        “Teachers are the backbone of any country, the pillars upon which all
                        aspirations are converted into realities”
                     </h4>
                     <h5>Partner Name</h5>
                     <h6>Position | Company</h6>
                  </div>
                  <div className='partners-review-boxes'>
                     <h4>
                        “Teachers are the backbone of any country, the pillars upon which all
                        aspirations are converted into realities”
                     </h4>
                     <h5>Partner Name</h5>
                     <h6>Position | Company</h6>
                  </div>
               </div>
            </div> */}

            {/* -------------------- partners bottom -------------- */}
            <div className='partners-bottom'>
               <div className='partners-bottom-heading'>
                  Interested in becoming an Innerscore partner?
               </div>
               <div className='partners-bottom-subheading'>
                  The app showcase daily tips to strenghten the teacher’s engagement with students
                  and parents. Discover the well-stocked library of teacher content.
               </div>
               <button className='partners-bottom-button'>
                  <Link style={{ color: 'black', textDecoration: 'none' }} to='/partnership-form'>
                     Become an our partner
                  </Link>
               </button>
            </div>
         </div>
      </>
   );
};

export default Partnership;
