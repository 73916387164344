import './button.scss';

const ButtonOutlined = props => {
   return (
      <button className='button-outlined' href='#'>
         {props.text}
      </button>
   );
};

export default ButtonOutlined;
