import "./button.scss";
import { useLocation } from "react-router-dom";
import { useEffect, useState} from "react";
const Button = () => {
const [buttonClass,setButtonClass]=useState('download-button');
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/students") {
      setButtonClass("download-button-student");
    } else if (location.pathname === "/parents") {
      setButtonClass("download-button-parent");
    } else {
      setButtonClass("download-button");
    }
  }, [location]);
  return (
    <a href={`https://play.google.com/store/apps/details?id=com.innerscore&referrer=utm_source%3DWebsite%26utm_medium%3DBanner%26utm_campaign%3DDownloadApp`} target='_blank' rel='noreferrer'>
    <button className={`${buttonClass}`} href="" >
     Download App 
  </button>
   </a>
  );
};

export default Button;