import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';

const WatchOurVideo = () => {
   const [isOpen, setOpen] = useState(false);

   return (
      <>
         <button
            className='download-button'
            style={{ marginLeft: '0.5rem', width: '180px' }}
            onClick={() => setOpen(true)}>
            App Demo
         </button>
         <ModalVideo
            channel='youtube'
            autoplay
            isOpen={isOpen}
            videoId='5ZxE-EqUamk'
            allowFullScreen='true'
            onClose={() => setOpen(false)}
         />
      </>
   );
};

export default WatchOurVideo;
