import './parallex.scss';
import DailyTip from './dailyTips/dailyTip';

export default function Parallex() {
   return (
      <>
      <div className='section'>
      <DailyTip />
      </div>
        
      </>
   );
}
