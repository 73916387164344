import './featuresHead.scss';

const FeaturesHead = () => {
   return (
      <section className='features-section'>
         <div className="extra_space"></div>
         <div className='features-container'>
            <div className='sub-heading'>Key Features</div>
            <div className='heading'>Save time, Up-skill effortlessly, Share and learn with peers</div>
         </div>
      </section>
   );
};

export default FeaturesHead;
