import './cta.scss';
import Button from '../../components/button/Button';
import CtaImg from '../../assets/images/CTA-img.png';
import WatchOurVideo from '../../components/button/WatchOurVideo';

const CTA = () => {
   return (
      <section className='cta-section'>
         <div className='cta-container'>
            <div className='cta-text'>
               <div className='cta-heading'>
                  <h3>
                     Grow as a <br /> teacher with us
                  </h3>
               </div>
               <div className='cta-sub-heading'>
                  Join the Teacher Community <br /> and get the best practices and experiences
               </div>
               {/* <button className="button-outline cta-btn">Download App</button> */}
               <div className='cta-buttons'>
                  <Button />
                  <WatchOurVideo />
               </div>
            </div>

            <div className='cta-img'>
               <img src={CtaImg} alt='Daily Tips ' />
            </div>
         </div>
      </section>
   );
};

export default CTA;
