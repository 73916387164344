import './hero.scss';
import Button from '../../components/button/Button';
import heroImage from '../../assets/images/hero-image.png';
import playButton from '../../assets/images/play_button.svg';

// for video modal
import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';

const Hero = () => {
   const [isOpen, setOpen] = useState(false);

   return (
      <section className='hero-section'>
         <div className='hero-container'>
            {/* left container */}

            <div className='hero-container-left'>
               <div className='hash-tag'>#RISEHIGHER</div>
               <div className='main-text'>A community for teachers to share and learn</div>
               <div className='helper-text'>
                  Join the Teacher Community and get the best practices and experiences
               </div>
               <div className='button'>
                  <Button />
               </div>
            </div>

            {/* right-cotainer */}
            <div className='hero-container-right'>
               <img id='hero_image' src={heroImage} alt='hero' />
               <img
                  id='play_btn'
                  className='btn-primary'
                  onClick={() => setOpen(true)}
                  src={playButton}
                  alt='play'
               />
               {/* <button className="btn-primary" onClick={()=> setOpen(true)}></button>            */}
            </div>
         </div>
         <ModalVideo
            channel='youtube'
            autoplay
            isOpen={isOpen}
            videoId='UKu3k3JphSk'
            allowFullScreen='true'
            onClose={() => setOpen(false)}
         />
      </section>
   );
};

export default Hero;
