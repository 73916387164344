import './footer.scss';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import { GoLocation } from 'react-icons/go';
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';
import { BsInstagram, BsTwitter, BsYoutube, BsMedium } from 'react-icons/bs';

const Footer = () => {
   return (
      <footer>
         {/* footer top */}
         <div className='footer-container'>
            {/* left container */}
            <div className='footer-left'>
               <div className='footer-heading'>#RISEHIGHER</div>
               <div className='footer-para'>
                  Teachers are the backbone of any country, the pillars upon which all aspirations
                  are converted into realities
               </div>
               <div className='footer-social-media'>
                  <div className='footer-social-media-box'>
                     <a
                        href='https://www.youtube.com/channel/UCRvUTObfcqsHuMupkiV4F0A?utm_source=Website&utm_medium=CTA&utm_campaign=ISWebsite_Tracking                        '
                        target='_blank'
                        rel='noreferrer'
                        style={{
                           color: '#241D1B',
                           textDecoration: 'none',
                           // transform: 'translateY(13%)',
                        }}>
                        <BsYoutube />
                     </a>
                  </div>
                  <div className='footer-social-media-box'>
                     <a
                        href='https://www.instagram.com/innerscore01/?hl=en&utm_source=Website&utm_medium=CTA&utm_campaign=ISWebsite_Tracking'
                        target='_blank'
                        rel='noreferrer'
                        style={{
                           color: '#241D1B',
                           textDecoration: 'none',
                           // transform: 'translateY(12%)',
                        }}>
                        <BsInstagram />
                     </a>
                  </div>
                  <div className='footer-social-media-box'>
                     <a
                        href='https://www.facebook.com/profile.php?id=100041345365848&utm_source=Website&utm_medium=CTA&utm_campaign=ISWebsite_Tracking'
                        target='_blank'
                        rel='noreferrer'
                        style={{
                           color: '#241D1B',
                           textDecoration: 'none',
                           // transform: 'translateY(10%)',
                        }}>
                        <FaFacebookF />
                     </a>
                  </div>

                  <div className='footer-social-media-box'>
                     <a
                        href='https://medium.com/@innerscore?utm_source=Website&utm_medium=CTA&utm_campaign=ISWebsite_Tracking'
                        target='_blank'
                        rel='noreferrer'
                        style={{
                           color: '#241D1B',
                           textDecoration: 'none',
                           // transform: 'translateY(13%)',
                        }}>
                        <BsMedium />
                     </a>
                  </div>

                  <div className='footer-social-media-box'>
                     <a
                        href='https://www.linkedin.com/company/innerscore/?originalSubdomain=in&utm_source=Website&utm_medium=CTA&utm_campaign=ISWebsite_Tracking                        '
                        target='_blank'
                        rel='noreferrer'
                        style={{
                           color: '#241D1B',
                           textDecoration: 'none',
                           // transform: 'translateY(10%)',
                        }}>
                        <FaLinkedinIn />
                     </a>
                  </div>

                  <div className='footer-social-media-box'>
                     <a
                        href='https://twitter.com/innerscore?lang=en&utm_source=Website&utm_medium=CTA&utm_campaign=ISWebsite_Tracking                        '
                        target='_blank'
                        rel='noreferrer'
                        style={{
                           color: '#241D1B',
                           textDecoration: 'none',
                           // transform: 'translateY(10%)',
                        }}>
                        <BsTwitter />
                     </a>
                  </div>
               </div>
            </div>

            {/* center container */}
            <div className='footer-center'>
               <div className='footer-links'>
                  <Link style={{ color: '#F5CDC1', textDecoration: 'none' }} to='/about'>
                     About us
                  </Link>
               </div>
               {/* <div className="footer-links"><Link style={{color:'#F5CDC1',textDecoration:'none'}} to="/partnership-form">Join the movement</Link></div> */}
               <div className='footer-links'>
                  <Link style={{ color: '#F5CDC1', textDecoration: 'none' }} to='/partnership'>
                     Partnership
                  </Link>
               </div>
               <div className='footer-links'>
                  <Link style={{ color: '#F5CDC1', textDecoration: 'none' }} to='/work-with-us'>
                     Work with us
                  </Link>
               </div>
               <div className='footer-links'>
                  <Link style={{ color: '#F5CDC1', textDecoration: 'none' }} to='/contact-us'>
                     Contact us
                  </Link>
               </div>
            </div>

            {/* right container */}
            <div className='footer-right'>
               <div className='footer-links'>
                  <p>01B110, WeWork Rajapushpa Summit,</p>
                  <p>Nanakramguda Rd, Financial District,</p>
                  <p>Hyderabad, Telangana, 500032</p>
               </div>

               <div className='footer-links'>
                  <div className='footer-link-hover' style={{ marginBottom: '8px' }}>
                     <a
                        target='_blank'
                        href='https://goo.gl/maps/WFm9fCtpievwbNa36'
                        rel='noreferrer'>
                        Google Maps{' '}
                        <GoLocation
                           style={{
                              color: '#C09BD1',
                              fontSize: '16px',
                              marginLeft: '5px',
                           }}
                        />
                     </a>
                  </div>
                  <div className='footer-link-hover' style={{ marginBottom: '8px' }}>
                     <a href='tel:+917075780899'>
                        +91-7075780899{' '}
                        <AiOutlinePhone
                           style={{
                              color: '#C09BD1',
                              fontSize: '16px',
                              marginLeft: '5px',
                           }}
                        />
                     </a>
                  </div>
                  <div className='footer-link-hover' style={{ marginBottom: '8px' }}>
                     <a href='mailto: info@innerscore.com'>
                        info@innerscore.com{' '}
                        <AiOutlineMail
                           style={{
                              color: '#C09BD1',
                              fontSize: '16px',
                              marginLeft: '5px',
                           }}
                        />
                     </a>
                  </div>
               </div>
            </div>
         </div>

         {/* footer bottom */}
         <div className='footer-bottom'>
            <div className='footer-bottom-left'>
               <div className='footer-bottom-left-left footer-links'>
                  © 2022 Innerscore. All right reserved.
               </div>
               <div className='footer-bottom-left-right'>
                  <div className='footer-bottom-left-right-links'>
                     <Link
                        style={{ color: '#F5CDC1', textDecoration: 'none' }}
                        to='/privacy-policy'>
                        Privacy Policy
                     </Link>
                  </div>

                  <div className='footer-bottom-left-right-links'>
                     <Link style={{ color: '#F5CDC1', textDecoration: 'none' }} to='/'>
                        Terms and Conditions
                     </Link>
                  </div>
               </div>
            </div>

            <div className='footer-bottom-right footer-links'>Made in 💛 Hyderabad</div>
         </div>
      </footer>
   );
};

export default Footer;
