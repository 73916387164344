import { React, useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/partnershipForm.scss';
import Heading from '../components/Heading/Heading';
import ReactGA from 'react-ga';
const Partnership = () => {
   useEffect(() => {
      ReactGA.pageview(window.location.pathname);
   });
   const top = {
      heading: 'Become an Innerscore Partner',
      paragraph: 'Fill out the form and we will contact you within 24 hours.',
   };

   const [name, setName] = useState('');
   const [email, setEmail] = useState('');
   const [phone, setPhone] = useState('');
   const [company, setCompany] = useState('');
   const [website, setWebsite] = useState('');
   const [street1, setStreet1] = useState('');
   const [street2, setStreet2] = useState('');
   const [city, setCity] = useState('');
   // eslint-disable-next-line no-unused-vars
   const [postCode, setPostCode] = useState('');

   function handleSubmit(e) {
      e.preventDefault();

      const data = JSON.stringify({
         Name: `${name}`,
         Phone: `${phone}`,
         Email: `${email}`,
         Website: `${website}`,
         City: `${city}`,
         Company: `${company}`,
         Address1: `${street1}`,
         Address2: `${street2}`,
      });

      var config = {
         method: 'post',
         url: 'https://services.innerscore.com/Services/UsersCollectionService.svc/PartnerDetails/',
         headers: {
            'Content-Type': 'application/json',
         },
         data: data,
      };

      axios(config)
         .then(function (response) {
            if (response.statusText === 'OK') {
               alert('Your response has been submitted successfully.');
            } else {
               alert('oops! we are not able to get your response right now.');
            }
            console.log(JSON.stringify(response.data));
         })
         .catch(function (error) {
            console.log(error);
         });
   }

   return (
      <>
         <div className='partnershipForm'>
            {/* ---------------- heading container --------------- */}
            <Heading heading={top.heading} paragraph={top.paragraph} />

            {/* ------------------------ form --------------------- */}
            <div className='partners-form'>
               <form onSubmit={handleSubmit}>
                  <label className='partners-form-label'>Name *</label>
                  <br />
                  <input
                     className='partners-form-input'
                     type='text'
                     onChange={e => setName(e.target.value)}
                     required
                  />

                  <label className='partners-form-label'>Email *</label>
                  <br />
                  <input
                     className='partners-form-input'
                     type='email'
                     onChange={e => setEmail(e.target.value)}
                     required
                  />

                  <label className='partners-form-label'>Phone *</label>
                  <br />
                  <input
                     className='partners-form-input'
                     type='number'
                     onChange={e => setPhone(e.target.value)}
                     required
                  />

                  <label className='partners-form-label'>Company *</label>
                  <br />
                  <input
                     className='partners-form-input'
                     type='text'
                     onChange={e => setCompany(e.target.value)}
                     required
                  />

                  <label className='partners-form-label'>Website </label>
                  <br />
                  <input
                     className='partners-form-input'
                     type='text'
                     onChange={e => setWebsite(e.target.value)}
                  />

                  <label className='partners-form-label'>Street 1 </label>
                  <br />
                  <input
                     className='partners-form-input'
                     type='text'
                     onChange={e => setStreet1(e.target.value)}
                  />

                  <label className='partners-form-label'>Street 2 </label>
                  <br />
                  <input
                     className='partners-form-input'
                     type='text'
                     onChange={e => setStreet2(e.target.value)}
                  />

                  <div className='form-box'>
                     <div className='form-box1'>
                        <label className='partners-form-label'>City </label>
                        <br />
                        <input
                           className='partners-form-input'
                           type='text'
                           onChange={e => setCity(e.target.value)}
                        />
                     </div>

                     <div className='form-box2'>
                        <label className='partners-form-label'>Post Code </label>
                        <br />
                        <input
                           className='partners-form-input'
                           type='number'
                           onChange={e => setPostCode(e.target.value)}
                        />
                     </div>
                  </div>

                  <button type='submit' className='partners-button'>
                     Submit
                  </button>
               </form>
            </div>
         </div>
      </>
   );
};

export default Partnership;
