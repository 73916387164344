import React from 'react';
// import useEffect from 'react';
import axios from 'axios';
// import { Link } from 'react-router-dom';

import './../styles/workWithUs.scss';
import Heading from '../components/Heading/Heading';

import team from '../assets/images/work-with-us/team.png';
import internship from '../assets/images/work-with-us/MicrosoftTeams-image.png';
import createtive from '../assets/images/work-with-us/Creative.png';
import Engaging from '../assets/images/work-with-us/Engaging.png';
import fun from '../assets/images/work-with-us/Fun.png';
import Flexible from '../assets/images/work-with-us/Flexible.png';

import ReactGA from 'react-ga';
const Partnership = () => {
   const top = {
      heading: 'Work with us',
      paragraph: 'Get in touch with our team to learn more about Innerscore',
   };

   const [department, setDepartment] = React.useState([]);
   const [workType, setWorkType] = React.useState([]);
   const [location, setLocation] = React.useState([]);
   const [jobList, setJobList] = React.useState([]);
   // console.log(jobList);

   //  --------- calling API for Department, Work Type , Location ---------  //

   React.useEffect(() => {
      ReactGA.pageview(window.location.pathname);

      axios
         .get(
            'https://services.innerscore.com/Services/UsersCollectionService.svc/GetFilters/department'
         )
         .then(response => {
            const data = JSON.parse(response.data);
            setDepartment(data);
         });
   }, [setDepartment]);

   React.useEffect(() => {
      axios
         .get(
            'https://services.innerscore.com/Services/UsersCollectionService.svc/GetFilters/worktype'
         )
         .then(response => {
            const data = JSON.parse(response.data);
            setWorkType(data);
         });
   }, [setWorkType]);

   React.useEffect(() => {
      axios
         .get(
            'https://services.innerscore.com/Services/UsersCollectionService.svc/GetFilters/location'
         )
         .then(response => {
            const data = JSON.parse(response.data);
            setLocation(data);
         });
   }, [setLocation]);

   //  --------- calling API for Job List and filter ---------  //

   const [filteredDepartment, setFilteredDepartment] = React.useState();
   const [filteredWorkType, setFilteredWorkType] = React.useState();
   const [filteredLocation, setFilteredLocation] = React.useState();
   const [isRemote, setIsRemote] = React.useState();
   const [error, setError] = React.useState('');

   // console.log(error);

   AllJobs();

   function AllJobs() {
      React.useEffect(() => {
         const data = JSON.stringify({
            Department: filteredDepartment,
            worktype: filteredWorkType,
            location: filteredLocation,
            is_remote: isRemote,
         });

         const config = {
            method: 'post',
            url: 'https://services.innerscore.com/Services/UsersCollectionService.svc/GetJobs/',
            headers: {
               'Content-Type': 'application/json',
            },
            data: data,
         };

         axios(config)
            .then(function (response) {
               const data = JSON.parse(response.data);
               setJobList(data);
            })
            .catch(function (error) {
               // console.error(error.message);
               // console.log('No jobs Found');
               setError(error.message);
               setJobList([]);
            });
      }, [filteredDepartment, filteredLocation, filteredWorkType, isRemote]);
   }

   //  {/* ---------------- clear all handler --------------- */}

   function handleClear() {
      console.log('clear');

      setFilteredDepartment();
      setFilteredWorkType();
      setFilteredLocation();
      setIsRemote();
      setError();
   }

   return (
      <>
         <div className='work'>
            {/* ---------------- heading container --------------- */}
            <Heading heading={top.heading} paragraph={top.paragraph} />

            {/* ----------------- desc box ---------------------- */}
            <div className='work-desc'>
               <div className='work-desc-left'>
                  <h2>Join The Innerscore Family</h2>
                  <p>
                     We’re determined to keep a good company vibe – friendly, personal, warm and
                     welcoming.
                  </p>
                  <p>
                     You’ll never be just an employee. You’ll always be you, with all your talent
                     and potential and the opportunity to take it places. You’ll get responsibility,
                     be challenged and set the course of your own future. It’s a journey we’ll take
                     together. Join us!
                  </p>
                  <p>
                     <a
                        onClick={() => {
                           handleClear();
                           AllJobs();
                        }}
                        href='#openPosition'
                        style={{ color: 'blue', fontWeight: '500' }}>
                        See open positions
                     </a>
                  </p>
               </div>
               <div className='work-desc-right'>
                  <img className='work-team-img' src={internship} alt='' />
                  {/* <img className='work-fingerprint-img' src={fingerprint} alt='' /> */}
                  <div>
                     <svg
                        className='work-fingerprint-img'
                        width='230'
                        height='270'
                        viewBox='0 0 230 270'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <g clip-path='url(#clip0_272_42307)'>
                           <path
                              d='M3.49351 130.136C1.90112 129.492 0.990593 127.807 1.32409 126.122C5.19249 95.8654 17.6952 67.3654 37.3353 44.0336C37.857 43.269 38.7039 42.7899 39.6277 42.7368C40.5516 42.6837 41.4479 43.0625 42.0537 43.7623C43.265 45.3678 43.265 47.5823 42.0537 49.1878C23.5663 71.1669 11.7985 98.0081 8.15762 126.501C8.04536 128.532 6.3939 130.137 4.36128 130.191C4.07624 130.288 3.7642 130.269 3.49351 130.136Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M52.3043 36.1667C51.6144 35.9289 51.0355 35.4463 50.6773 34.8103C49.7485 32.9876 50.1477 30.7691 51.6535 29.3848C73.6498 8.95246 103.041 -1.59336 133.004 0.195523C158.366 2.2755 181.868 14.3337 198.356 33.7252C199.605 35.3179 199.605 37.5581 198.356 39.1507C197.843 39.9226 197.001 40.4122 196.077 40.476C195.152 40.5398 194.251 40.1704 193.637 39.4763C178.088 21.2366 155.962 9.88618 132.082 7.89976C103.831 6.23352 76.1273 16.1905 55.3956 35.4614C54.5857 36.2703 53.3847 36.5443 52.3043 36.1667Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M223.9 147.932C222.305 147.265 221.415 145.552 221.785 143.863C226.517 112.706 220.286 80.8713 204.159 53.7994C203.203 52.0002 203.558 49.7851 205.027 48.3739C205.672 47.7223 206.58 47.4036 207.491 47.5095C208.401 47.6154 209.212 48.1342 209.691 48.9165C226.8 77.655 233.395 111.448 228.347 144.514C228.233 146.536 226.575 148.124 224.551 148.149L223.9 147.932Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M217.5 176.254C215.709 175.354 214.907 173.23 215.656 171.371C218.551 162.406 220.638 153.2 221.893 143.863C222.007 141.842 223.665 140.253 225.689 140.228C226.629 140.384 227.464 140.92 227.997 141.71C228.531 142.5 228.716 143.475 228.509 144.406C227.141 154.299 224.89 164.049 221.784 173.541C221.577 174.47 221.009 175.278 220.205 175.787C219.401 176.296 218.428 176.464 217.5 176.254Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M2.84284 135.562C1.3404 134.913 0.469485 133.327 0.727668 131.71C0.727668 129.757 1.16152 127.75 1.43269 125.796C1.54495 123.765 3.1964 122.161 5.22903 122.107C6.17104 122.275 7.00207 122.824 7.52569 123.625C8.04931 124.427 8.21909 125.408 7.99497 126.339C7.70573 128.184 7.48883 129.992 7.3442 131.765C7.26832 133.798 5.68371 135.453 3.65624 135.617C3.38408 135.65 3.10814 135.632 2.84284 135.562Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M48.942 252.591C48.1389 252.331 47.4865 251.738 47.1522 250.963C46.3115 249.057 46.8697 246.823 48.5081 245.537C83.5841 218.679 106.329 178.787 111.582 134.911C111.723 132.881 113.398 131.299 115.433 131.276C116.347 131.493 117.134 132.072 117.614 132.879C118.095 133.687 118.227 134.655 117.982 135.562C112.4 181.821 88.4097 223.874 51.4367 252.211C50.7051 252.708 49.7883 252.847 48.942 252.591Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M37.1186 242.499C36.2772 242.192 35.6064 241.541 35.2746 240.709C34.4912 238.742 35.1653 236.494 36.9016 235.284C50.5148 226.176 62.3055 214.601 71.6655 201.157C72.1219 200.349 72.9172 199.788 73.8317 199.631C74.7462 199.473 75.6832 199.735 76.3838 200.343C77.6736 201.922 77.6736 204.19 76.3838 205.769C66.4753 220.059 53.964 232.355 39.5048 242.011C38.8123 242.5 37.9473 242.677 37.1186 242.499Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M62.5002 260.892C61.7771 260.63 61.1776 260.107 60.8189 259.427C59.9483 257.567 60.4126 255.354 61.9578 254.001C97.0112 223.958 119.615 181.924 125.357 136.105V134.966C126.205 129.124 122.229 123.676 116.408 122.704C110.552 122.736 105.544 126.926 104.477 132.687V134.423C102.001 152.933 96.369 170.88 87.8273 187.485C87.5049 188.376 86.8046 189.078 85.9149 189.402C85.0251 189.727 84.0374 189.641 83.2174 189.167C81.5305 187.888 81.1059 185.528 82.2411 183.741C89.6802 169.209 94.769 153.588 97.3181 137.461L97.8063 133.718C99.3248 122.433 108.273 113.915 117.71 114.674C127.147 115.434 133.655 125.2 132.136 136.376L131.648 139.849C125.002 186.75 101.452 229.604 65.4287 260.349C64.6054 261.01 63.5055 261.214 62.5002 260.892Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M27.0856 230.889C26.1937 230.553 25.4978 229.837 25.1874 228.935C24.4361 226.931 25.1808 224.673 26.9771 223.51C56.5157 204.723 76.8052 174.386 82.8921 139.903C83.1185 137.912 84.8511 136.44 86.8511 136.539C87.7734 136.781 88.5611 137.381 89.0397 138.206C89.5182 139.031 89.6479 140.012 89.4001 140.933C82.1328 178.912 59.7885 212.55 29.743 230.563C28.9584 231.085 27.9731 231.206 27.0856 230.889Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M133.6 176.362C131.754 175.543 130.889 173.406 131.648 171.533C135.064 160.306 137.567 148.821 139.132 137.19V135.616C139.876 128.777 138.27 121.888 134.577 116.085C131.011 110.413 124.982 106.754 118.307 106.21C107.643 106.032 97.9058 112.248 93.576 121.998C93.2385 122.877 92.5374 123.566 91.6536 123.889C90.7698 124.211 89.7898 124.135 88.9662 123.68C87.284 122.403 86.8391 120.06 87.9357 118.255C93.4012 105.825 105.821 97.9202 119.391 98.2346C127.782 98.9658 135.339 103.61 139.783 110.768C144.761 118.736 146.687 128.237 145.207 137.515L145.098 140.988C143.44 152.005 140.975 162.885 137.722 173.541C137.29 175.479 135.382 176.711 133.438 176.308L133.6 176.362Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M77.9566 266.914C77.3192 266.665 76.7713 266.23 76.3838 265.666C75.4332 263.876 75.7633 261.674 77.1972 260.241C97.7665 240.821 113.962 217.239 124.706 191.065C124.975 190.164 125.609 189.417 126.454 189.005C127.299 188.592 128.278 188.553 129.153 188.895C130.898 189.952 131.535 192.174 130.617 193.995C119.408 221.331 102.51 245.966 81.0478 266.263C80.2089 267.02 79.0291 267.268 77.9566 266.914Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M18.7878 218.03C17.8128 217.696 17.0632 216.906 16.7811 215.914C16.0699 213.858 16.9102 211.586 18.7878 210.489C45.3081 196.057 64.7237 167.085 69.8759 133.826L70.2014 131.33C74.1063 102.033 97.3182 79.788 121.995 81.7954C126.126 82.1289 130.175 83.1369 133.98 84.7795C135.734 85.7967 136.436 87.9746 135.607 89.8252C135.344 90.7385 134.724 91.507 133.887 91.9564C133.049 92.4059 132.066 92.4982 131.16 92.2124C127.924 90.8012 124.481 89.9218 120.964 89.6082C100.518 87.9805 81.2109 105.885 77.0891 129.865V131.873C72.0996 168.766 50.7315 201.591 21.3369 217.596C20.5973 218.121 19.6593 218.281 18.7878 218.03Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M95.3114 270.115C94.7459 269.926 94.2531 269.566 93.9013 269.084C92.8649 267.362 93.0624 265.167 94.3895 263.659C126.44 228.959 146.893 185.135 152.908 138.275V136.81C154.449 124.847 151.343 112.749 144.23 103.009C143.127 101.32 143.283 99.1027 144.61 97.5835C145.187 96.8719 146.053 96.4586 146.969 96.4586C147.885 96.4586 148.752 96.8719 149.328 97.5835C158.169 109.566 161.842 124.594 159.524 139.306L159.09 142.453C152.284 189.995 131.195 234.346 98.6197 269.627C97.7077 270.424 96.4146 270.614 95.3114 270.115Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M12.117 204.141C11.1211 203.778 10.3698 202.945 10.1105 201.917C9.53131 199.929 10.3816 197.802 12.1713 196.762C23.5138 190.847 33.2341 182.241 40.4813 171.696C40.9378 170.888 41.7331 170.328 42.6475 170.17C43.562 170.012 44.499 170.274 45.1997 170.883C46.511 172.453 46.511 174.737 45.1997 176.308C37.2484 187.918 26.5554 197.385 14.0695 203.87C13.4695 204.183 12.7796 204.279 12.117 204.141Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M147.105 218.084H146.617C144.836 216.887 144.29 214.513 145.37 212.658C156.178 189.47 163.401 164.773 166.792 139.414V138.22C170.805 104.691 151.39 75.7726 123.405 73.4397C95.4205 71.1067 69.1712 95.9013 63.7479 128.888V131.004C62.5544 139.794 60.2585 148.398 56.9144 156.613C56.6448 157.528 56.0052 158.288 55.1504 158.711C54.2955 159.133 53.3032 159.179 52.4131 158.837C50.6905 157.764 50.0582 155.561 50.9488 153.737C53.6875 146.982 55.6524 139.938 56.8059 132.741L57.0772 130.462C62.2294 92.1034 92.6545 63.0227 124.869 65.3556C157.084 67.6886 179.103 101.273 173.951 139.685L173.625 142.127C170.029 167.562 162.72 192.331 151.932 215.642C151.696 216.66 151.021 217.52 150.089 217.992C149.157 218.463 148.064 218.497 147.105 218.084Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M114.727 269.844C114.245 269.692 113.813 269.41 113.48 269.03C112.23 267.438 112.23 265.197 113.48 263.605C121.827 253.4 129.302 242.511 135.824 231.052C136.203 230.195 136.932 229.544 137.825 229.263C138.718 228.983 139.688 229.101 140.488 229.587C142.032 230.962 142.374 233.244 141.301 235.012C134.447 246.836 126.649 258.085 117.981 268.65C117.224 269.652 115.952 270.119 114.727 269.844Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M136.312 264.852C135.914 264.73 135.544 264.527 135.228 264.255C133.916 262.718 133.805 260.49 134.956 258.83C159.104 223.828 174.656 183.621 180.35 141.476V140.608C181.933 129.594 181.399 118.38 178.777 107.566C178.272 105.531 179.24 103.416 181.109 102.466C181.955 102.104 182.922 102.152 183.727 102.597C184.533 103.043 185.088 103.835 185.231 104.745C187.968 116.2 188.611 128.056 187.129 139.74V141.15C181.328 185.091 165.175 227.024 140 263.496C139.226 264.739 137.707 265.297 136.312 264.852Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M174.059 91.3987C173.309 91.1303 172.689 90.5874 172.324 89.8795C161.401 66.1286 135.661 52.9285 110.009 57.9232C109.088 58.0557 108.156 57.766 107.472 57.1346C106.788 56.5031 106.425 55.5971 106.484 54.6679C106.278 52.6134 107.584 50.7104 109.575 50.1647C115.127 49.0743 120.804 48.7639 126.442 49.2424C148.787 51.4125 168.38 65.0901 178.126 85.3221C179.012 87.2019 178.496 89.4436 176.879 90.7476C176.119 91.4466 175.048 91.694 174.059 91.3987Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M7.12712 189.383C6.1418 189.005 5.39641 188.178 5.12056 187.159C4.42805 185.077 5.3169 182.797 7.23562 181.733C26.3527 171.426 39.4227 152.629 42.4333 131.113V129.106C46.772 97.0411 65.5369 69.0455 91.2437 56.0785C92.0618 55.6735 93.0202 55.6656 93.8449 56.0569C94.6696 56.4483 95.2699 57.1959 95.4739 58.0859C96.1664 60.168 95.2776 62.4482 93.3588 63.5114C69.2773 76.6751 52.8735 100.495 49.1583 127.695V129.866C46.4301 154.928 31.6254 177.073 9.51342 189.166C8.78533 189.576 7.91709 189.655 7.12712 189.383Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M3.76541 173.54C2.80604 173.2 2.07582 172.408 1.81299 171.424C1.12389 169.369 1.95911 167.111 3.81966 165.999C17.1048 158.429 26.2403 145.239 28.6587 130.136V128.454C29.4102 122.967 30.4969 117.532 31.9127 112.178C32.0167 110.82 32.9293 109.658 34.2241 109.236C35.5189 108.814 36.9403 109.215 37.8239 110.252C38.7075 111.288 38.879 112.756 38.2581 113.968C37.1308 118.168 36.2256 122.423 35.5464 126.718L35.2752 128.888C33.11 147.535 22.3017 164.075 6.09747 173.54C5.34779 173.834 4.51509 173.834 3.76541 173.54Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M194.342 158.621C192.703 157.885 191.833 156.075 192.281 154.335C192.932 150.754 193.474 147.119 194.017 143.483V141.693C201.067 89.5539 171.13 44.3051 127.363 40.7242C110.512 39.7022 93.8149 44.4474 80.0174 54.1795C65.0487 64.5073 53.1345 78.6751 45.5248 95.1964C45.2158 96.087 44.5418 96.8035 43.6719 97.166C42.8021 97.5286 41.8189 97.5029 40.9692 97.0953C39.1578 95.9158 38.6267 93.5009 39.7761 91.6698C48.0069 73.7636 60.9167 58.412 77.1431 47.2348C92.172 36.7014 110.359 31.6454 128.665 32.9115C175.252 36.6551 207.358 84.0741 201.23 139.252L200.85 142.236C200.254 146.63 199.549 151.025 198.789 155.311C198.59 157.331 196.858 158.85 194.83 158.783L194.342 158.621Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M160.392 252.971L159.578 252.591C158.031 251.212 157.71 248.916 158.819 247.165C171.754 224.373 181.551 199.936 187.942 174.518C188.275 172.56 190.097 171.218 192.064 171.479C192.977 171.787 193.727 172.449 194.146 173.317C194.564 174.185 194.615 175.185 194.288 176.091C187.736 202.355 177.628 227.6 164.242 251.126C163.598 252.615 161.955 253.402 160.392 252.971Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M2.19221 156.939C1.35762 156.682 0.681497 156.065 0.348291 155.257C-0.457449 153.315 0.169983 151.073 1.86683 149.832C8.92865 144.618 13.6304 136.812 14.9371 128.13V126.882C18.9039 95.9669 33.1774 67.2941 55.4498 45.499C75.1723 25.4333 102.589 14.854 130.672 16.4725C141.772 17.3342 152.583 20.4347 162.453 25.5874C164.144 26.7144 164.726 28.9279 163.809 30.7416C163.516 31.6367 162.867 32.3712 162.015 32.7717C161.163 33.1723 160.183 33.2033 159.307 32.8576C150.089 28.0494 140.003 25.1347 129.641 24.2853C103.329 22.7329 77.6285 32.6217 59.1376 51.4129C38.8142 71.333 25.6234 97.401 21.6079 125.58L21.3368 127.641C19.9317 139.177 13.8287 149.621 4.47002 156.505C3.80033 156.951 2.97893 157.108 2.19221 156.939Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M187.617 229.261H187.238C185.374 228.135 184.749 225.727 185.828 223.836C196.434 198.587 203.726 172.071 207.521 144.949L207.792 142.616C213.216 102.467 199.874 63.8371 173.028 41.8637C171.552 40.4282 171.323 38.1378 172.486 36.4382C172.885 35.5958 173.642 34.9782 174.547 34.7571C175.452 34.536 176.408 34.7351 177.15 35.2989C205.135 58.1945 219.398 97.9636 214.788 139.74L214.354 143.158C210.49 171.942 202.793 200.079 191.468 226.82C190.935 228.452 189.32 229.476 187.617 229.261Z'
                              fill='#F5CDC1'
                           />
                        </g>
                        <defs>
                           <clipPath id='clip0_272_42307'>
                              <rect width='229.668' height='270' fill='white' />
                           </clipPath>
                        </defs>
                     </svg>
                  </div>
               </div>
            </div>

            {/* ----------------- desc box ---------------------- */}
            <div className='work-benifit'>
               <h2>Benefits of working at Innerscore</h2>
               <div className='work-benifit-boxes'>
                  <div className='work-benifit-box'>
                     <img src={Flexible} alt='' />
                     <h4>Flexible</h4>
                     {/* <p>
                        Remote working isn't a problem. You can also choose the tools you prefer.
                     </p> */}
                  </div>

                  <div className='work-benifit-box'>
                     <img src={fun} alt='' />
                     <h4>Fun</h4>
                     {/* <p>
                        Remote working isn't a problem. You can also choose the tools you prefer.
                     </p> */}
                  </div>

                  <div className='work-benifit-box'>
                     <img src={createtive} alt='' />
                     <h4>Creative</h4>
                     {/* <p>
                        Remote working isn't a problem. You can also choose the tools you prefer.
                     </p> */}
                  </div>

                  <div className='work-benifit-box'>
                     <img src={Engaging} alt='' />
                     <h4>Engaging</h4>
                     {/* <p>
                        Remote working isn't a problem. You can also choose the tools you prefer.
                     </p> */}
                  </div>
               </div>
            </div>

            {/* ----------------- desc box for Intern postion---------------------- */}
            <div className='work-desc'>
               <div className='work-desc-left'>
                  <h2>We're hiring interns !</h2>
                  <p>
                     Internships at Innerscore are aimed for students to kick start their career and
                     gain experience in their area of interests. As an Intern with Innerscore, you
                     will be working on Product, Content, Marketing, etc wherever you wish to add
                     value. We believe that fresh talent brings new ideas and we encourage students
                     to bring all their imagination. Innerscore will be your platform to start
                     something new.
                  </p>

                  {/* <p> */}
                  <p onClick={() => setFilteredWorkType('Internship')}>
                     <a href='#openPosition' style={{ color: 'black', fontWeight: '500' }}>
                        See open positions
                     </a>
                  </p>
               </div>
               <div className='work-desc-right'>
                  <img className='work-team-img' src={team} alt='' />
                  {/* <img className='work-fingerprint-img' src={fingerprint} alt='' /> */}
                  <div>
                     <svg
                        className='work-fingerprint-img'
                        width='230'
                        height='270'
                        viewBox='0 0 230 270'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <g clip-path='url(#clip0_272_42307)'>
                           <path
                              d='M3.49351 130.136C1.90112 129.492 0.990593 127.807 1.32409 126.122C5.19249 95.8654 17.6952 67.3654 37.3353 44.0336C37.857 43.269 38.7039 42.7899 39.6277 42.7368C40.5516 42.6837 41.4479 43.0625 42.0537 43.7623C43.265 45.3678 43.265 47.5823 42.0537 49.1878C23.5663 71.1669 11.7985 98.0081 8.15762 126.501C8.04536 128.532 6.3939 130.137 4.36128 130.191C4.07624 130.288 3.7642 130.269 3.49351 130.136Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M52.3043 36.1667C51.6144 35.9289 51.0355 35.4463 50.6773 34.8103C49.7485 32.9876 50.1477 30.7691 51.6535 29.3848C73.6498 8.95246 103.041 -1.59336 133.004 0.195523C158.366 2.2755 181.868 14.3337 198.356 33.7252C199.605 35.3179 199.605 37.5581 198.356 39.1507C197.843 39.9226 197.001 40.4122 196.077 40.476C195.152 40.5398 194.251 40.1704 193.637 39.4763C178.088 21.2366 155.962 9.88618 132.082 7.89976C103.831 6.23352 76.1273 16.1905 55.3956 35.4614C54.5857 36.2703 53.3847 36.5443 52.3043 36.1667Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M223.9 147.932C222.305 147.265 221.415 145.552 221.785 143.863C226.517 112.706 220.286 80.8713 204.159 53.7994C203.203 52.0002 203.558 49.7851 205.027 48.3739C205.672 47.7223 206.58 47.4036 207.491 47.5095C208.401 47.6154 209.212 48.1342 209.691 48.9165C226.8 77.655 233.395 111.448 228.347 144.514C228.233 146.536 226.575 148.124 224.551 148.149L223.9 147.932Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M217.5 176.254C215.709 175.354 214.907 173.23 215.656 171.371C218.551 162.406 220.638 153.2 221.893 143.863C222.007 141.842 223.665 140.253 225.689 140.228C226.629 140.384 227.464 140.92 227.997 141.71C228.531 142.5 228.716 143.475 228.509 144.406C227.141 154.299 224.89 164.049 221.784 173.541C221.577 174.47 221.009 175.278 220.205 175.787C219.401 176.296 218.428 176.464 217.5 176.254Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M2.84284 135.562C1.3404 134.913 0.469485 133.327 0.727668 131.71C0.727668 129.757 1.16152 127.75 1.43269 125.796C1.54495 123.765 3.1964 122.161 5.22903 122.107C6.17104 122.275 7.00207 122.824 7.52569 123.625C8.04931 124.427 8.21909 125.408 7.99497 126.339C7.70573 128.184 7.48883 129.992 7.3442 131.765C7.26832 133.798 5.68371 135.453 3.65624 135.617C3.38408 135.65 3.10814 135.632 2.84284 135.562Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M48.942 252.591C48.1389 252.331 47.4865 251.738 47.1522 250.963C46.3115 249.057 46.8697 246.823 48.5081 245.537C83.5841 218.679 106.329 178.787 111.582 134.911C111.723 132.881 113.398 131.299 115.433 131.276C116.347 131.493 117.134 132.072 117.614 132.879C118.095 133.687 118.227 134.655 117.982 135.562C112.4 181.821 88.4097 223.874 51.4367 252.211C50.7051 252.708 49.7883 252.847 48.942 252.591Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M37.1186 242.499C36.2772 242.192 35.6064 241.541 35.2746 240.709C34.4912 238.742 35.1653 236.494 36.9016 235.284C50.5148 226.176 62.3055 214.601 71.6655 201.157C72.1219 200.349 72.9172 199.788 73.8317 199.631C74.7462 199.473 75.6832 199.735 76.3838 200.343C77.6736 201.922 77.6736 204.19 76.3838 205.769C66.4753 220.059 53.964 232.355 39.5048 242.011C38.8123 242.5 37.9473 242.677 37.1186 242.499Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M62.5002 260.892C61.7771 260.63 61.1776 260.107 60.8189 259.427C59.9483 257.567 60.4126 255.354 61.9578 254.001C97.0112 223.958 119.615 181.924 125.357 136.105V134.966C126.205 129.124 122.229 123.676 116.408 122.704C110.552 122.736 105.544 126.926 104.477 132.687V134.423C102.001 152.933 96.369 170.88 87.8273 187.485C87.5049 188.376 86.8046 189.078 85.9149 189.402C85.0251 189.727 84.0374 189.641 83.2174 189.167C81.5305 187.888 81.1059 185.528 82.2411 183.741C89.6802 169.209 94.769 153.588 97.3181 137.461L97.8063 133.718C99.3248 122.433 108.273 113.915 117.71 114.674C127.147 115.434 133.655 125.2 132.136 136.376L131.648 139.849C125.002 186.75 101.452 229.604 65.4287 260.349C64.6054 261.01 63.5055 261.214 62.5002 260.892Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M27.0856 230.889C26.1937 230.553 25.4978 229.837 25.1874 228.935C24.4361 226.931 25.1808 224.673 26.9771 223.51C56.5157 204.723 76.8052 174.386 82.8921 139.903C83.1185 137.912 84.8511 136.44 86.8511 136.539C87.7734 136.781 88.5611 137.381 89.0397 138.206C89.5182 139.031 89.6479 140.012 89.4001 140.933C82.1328 178.912 59.7885 212.55 29.743 230.563C28.9584 231.085 27.9731 231.206 27.0856 230.889Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M133.6 176.362C131.754 175.543 130.889 173.406 131.648 171.533C135.064 160.306 137.567 148.821 139.132 137.19V135.616C139.876 128.777 138.27 121.888 134.577 116.085C131.011 110.413 124.982 106.754 118.307 106.21C107.643 106.032 97.9058 112.248 93.576 121.998C93.2385 122.877 92.5374 123.566 91.6536 123.889C90.7698 124.211 89.7898 124.135 88.9662 123.68C87.284 122.403 86.8391 120.06 87.9357 118.255C93.4012 105.825 105.821 97.9202 119.391 98.2346C127.782 98.9658 135.339 103.61 139.783 110.768C144.761 118.736 146.687 128.237 145.207 137.515L145.098 140.988C143.44 152.005 140.975 162.885 137.722 173.541C137.29 175.479 135.382 176.711 133.438 176.308L133.6 176.362Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M77.9566 266.914C77.3192 266.665 76.7713 266.23 76.3838 265.666C75.4332 263.876 75.7633 261.674 77.1972 260.241C97.7665 240.821 113.962 217.239 124.706 191.065C124.975 190.164 125.609 189.417 126.454 189.005C127.299 188.592 128.278 188.553 129.153 188.895C130.898 189.952 131.535 192.174 130.617 193.995C119.408 221.331 102.51 245.966 81.0478 266.263C80.2089 267.02 79.0291 267.268 77.9566 266.914Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M18.7878 218.03C17.8128 217.696 17.0632 216.906 16.7811 215.914C16.0699 213.858 16.9102 211.586 18.7878 210.489C45.3081 196.057 64.7237 167.085 69.8759 133.826L70.2014 131.33C74.1063 102.033 97.3182 79.788 121.995 81.7954C126.126 82.1289 130.175 83.1369 133.98 84.7795C135.734 85.7967 136.436 87.9746 135.607 89.8252C135.344 90.7385 134.724 91.507 133.887 91.9564C133.049 92.4059 132.066 92.4982 131.16 92.2124C127.924 90.8012 124.481 89.9218 120.964 89.6082C100.518 87.9805 81.2109 105.885 77.0891 129.865V131.873C72.0996 168.766 50.7315 201.591 21.3369 217.596C20.5973 218.121 19.6593 218.281 18.7878 218.03Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M95.3114 270.115C94.7459 269.926 94.2531 269.566 93.9013 269.084C92.8649 267.362 93.0624 265.167 94.3895 263.659C126.44 228.959 146.893 185.135 152.908 138.275V136.81C154.449 124.847 151.343 112.749 144.23 103.009C143.127 101.32 143.283 99.1027 144.61 97.5835C145.187 96.8719 146.053 96.4586 146.969 96.4586C147.885 96.4586 148.752 96.8719 149.328 97.5835C158.169 109.566 161.842 124.594 159.524 139.306L159.09 142.453C152.284 189.995 131.195 234.346 98.6197 269.627C97.7077 270.424 96.4146 270.614 95.3114 270.115Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M12.117 204.141C11.1211 203.778 10.3698 202.945 10.1105 201.917C9.53131 199.929 10.3816 197.802 12.1713 196.762C23.5138 190.847 33.2341 182.241 40.4813 171.696C40.9378 170.888 41.7331 170.328 42.6475 170.17C43.562 170.012 44.499 170.274 45.1997 170.883C46.511 172.453 46.511 174.737 45.1997 176.308C37.2484 187.918 26.5554 197.385 14.0695 203.87C13.4695 204.183 12.7796 204.279 12.117 204.141Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M147.105 218.084H146.617C144.836 216.887 144.29 214.513 145.37 212.658C156.178 189.47 163.401 164.773 166.792 139.414V138.22C170.805 104.691 151.39 75.7726 123.405 73.4397C95.4205 71.1067 69.1712 95.9013 63.7479 128.888V131.004C62.5544 139.794 60.2585 148.398 56.9144 156.613C56.6448 157.528 56.0052 158.288 55.1504 158.711C54.2955 159.133 53.3032 159.179 52.4131 158.837C50.6905 157.764 50.0582 155.561 50.9488 153.737C53.6875 146.982 55.6524 139.938 56.8059 132.741L57.0772 130.462C62.2294 92.1034 92.6545 63.0227 124.869 65.3556C157.084 67.6886 179.103 101.273 173.951 139.685L173.625 142.127C170.029 167.562 162.72 192.331 151.932 215.642C151.696 216.66 151.021 217.52 150.089 217.992C149.157 218.463 148.064 218.497 147.105 218.084Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M114.727 269.844C114.245 269.692 113.813 269.41 113.48 269.03C112.23 267.438 112.23 265.197 113.48 263.605C121.827 253.4 129.302 242.511 135.824 231.052C136.203 230.195 136.932 229.544 137.825 229.263C138.718 228.983 139.688 229.101 140.488 229.587C142.032 230.962 142.374 233.244 141.301 235.012C134.447 246.836 126.649 258.085 117.981 268.65C117.224 269.652 115.952 270.119 114.727 269.844Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M136.312 264.852C135.914 264.73 135.544 264.527 135.228 264.255C133.916 262.718 133.805 260.49 134.956 258.83C159.104 223.828 174.656 183.621 180.35 141.476V140.608C181.933 129.594 181.399 118.38 178.777 107.566C178.272 105.531 179.24 103.416 181.109 102.466C181.955 102.104 182.922 102.152 183.727 102.597C184.533 103.043 185.088 103.835 185.231 104.745C187.968 116.2 188.611 128.056 187.129 139.74V141.15C181.328 185.091 165.175 227.024 140 263.496C139.226 264.739 137.707 265.297 136.312 264.852Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M174.059 91.3987C173.309 91.1303 172.689 90.5874 172.324 89.8795C161.401 66.1286 135.661 52.9285 110.009 57.9232C109.088 58.0557 108.156 57.766 107.472 57.1346C106.788 56.5031 106.425 55.5971 106.484 54.6679C106.278 52.6134 107.584 50.7104 109.575 50.1647C115.127 49.0743 120.804 48.7639 126.442 49.2424C148.787 51.4125 168.38 65.0901 178.126 85.3221C179.012 87.2019 178.496 89.4436 176.879 90.7476C176.119 91.4466 175.048 91.694 174.059 91.3987Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M7.12712 189.383C6.1418 189.005 5.39641 188.178 5.12056 187.159C4.42805 185.077 5.3169 182.797 7.23562 181.733C26.3527 171.426 39.4227 152.629 42.4333 131.113V129.106C46.772 97.0411 65.5369 69.0455 91.2437 56.0785C92.0618 55.6735 93.0202 55.6656 93.8449 56.0569C94.6696 56.4483 95.2699 57.1959 95.4739 58.0859C96.1664 60.168 95.2776 62.4482 93.3588 63.5114C69.2773 76.6751 52.8735 100.495 49.1583 127.695V129.866C46.4301 154.928 31.6254 177.073 9.51342 189.166C8.78533 189.576 7.91709 189.655 7.12712 189.383Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M3.76541 173.54C2.80604 173.2 2.07582 172.408 1.81299 171.424C1.12389 169.369 1.95911 167.111 3.81966 165.999C17.1048 158.429 26.2403 145.239 28.6587 130.136V128.454C29.4102 122.967 30.4969 117.532 31.9127 112.178C32.0167 110.82 32.9293 109.658 34.2241 109.236C35.5189 108.814 36.9403 109.215 37.8239 110.252C38.7075 111.288 38.879 112.756 38.2581 113.968C37.1308 118.168 36.2256 122.423 35.5464 126.718L35.2752 128.888C33.11 147.535 22.3017 164.075 6.09747 173.54C5.34779 173.834 4.51509 173.834 3.76541 173.54Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M194.342 158.621C192.703 157.885 191.833 156.075 192.281 154.335C192.932 150.754 193.474 147.119 194.017 143.483V141.693C201.067 89.5539 171.13 44.3051 127.363 40.7242C110.512 39.7022 93.8149 44.4474 80.0174 54.1795C65.0487 64.5073 53.1345 78.6751 45.5248 95.1964C45.2158 96.087 44.5418 96.8035 43.6719 97.166C42.8021 97.5286 41.8189 97.5029 40.9692 97.0953C39.1578 95.9158 38.6267 93.5009 39.7761 91.6698C48.0069 73.7636 60.9167 58.412 77.1431 47.2348C92.172 36.7014 110.359 31.6454 128.665 32.9115C175.252 36.6551 207.358 84.0741 201.23 139.252L200.85 142.236C200.254 146.63 199.549 151.025 198.789 155.311C198.59 157.331 196.858 158.85 194.83 158.783L194.342 158.621Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M160.392 252.971L159.578 252.591C158.031 251.212 157.71 248.916 158.819 247.165C171.754 224.373 181.551 199.936 187.942 174.518C188.275 172.56 190.097 171.218 192.064 171.479C192.977 171.787 193.727 172.449 194.146 173.317C194.564 174.185 194.615 175.185 194.288 176.091C187.736 202.355 177.628 227.6 164.242 251.126C163.598 252.615 161.955 253.402 160.392 252.971Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M2.19221 156.939C1.35762 156.682 0.681497 156.065 0.348291 155.257C-0.457449 153.315 0.169983 151.073 1.86683 149.832C8.92865 144.618 13.6304 136.812 14.9371 128.13V126.882C18.9039 95.9669 33.1774 67.2941 55.4498 45.499C75.1723 25.4333 102.589 14.854 130.672 16.4725C141.772 17.3342 152.583 20.4347 162.453 25.5874C164.144 26.7144 164.726 28.9279 163.809 30.7416C163.516 31.6367 162.867 32.3712 162.015 32.7717C161.163 33.1723 160.183 33.2033 159.307 32.8576C150.089 28.0494 140.003 25.1347 129.641 24.2853C103.329 22.7329 77.6285 32.6217 59.1376 51.4129C38.8142 71.333 25.6234 97.401 21.6079 125.58L21.3368 127.641C19.9317 139.177 13.8287 149.621 4.47002 156.505C3.80033 156.951 2.97893 157.108 2.19221 156.939Z'
                              fill='#F5CDC1'
                           />
                           <path
                              d='M187.617 229.261H187.238C185.374 228.135 184.749 225.727 185.828 223.836C196.434 198.587 203.726 172.071 207.521 144.949L207.792 142.616C213.216 102.467 199.874 63.8371 173.028 41.8637C171.552 40.4282 171.323 38.1378 172.486 36.4382C172.885 35.5958 173.642 34.9782 174.547 34.7571C175.452 34.536 176.408 34.7351 177.15 35.2989C205.135 58.1945 219.398 97.9636 214.788 139.74L214.354 143.158C210.49 171.942 202.793 200.079 191.468 226.82C190.935 228.452 189.32 229.476 187.617 229.261Z'
                              fill='#F5CDC1'
                           />
                        </g>
                        <defs>
                           <clipPath id='clip0_272_42307'>
                              <rect width='229.668' height='270' fill='white' />
                           </clipPath>
                        </defs>
                     </svg>
                  </div>
               </div>
            </div>

            {/* ----------------- positions --------------------- */}
            <section id='openPosition'>
               <div className='work-position'>
                  <div className='work-position-inner'>
                     <h2>Open positions</h2>
                     <div className='work-position-top-boxes'>
                        {/* -------------------Department-------------------- */}

                        <div className='work-position-top-box work-position-top-box1'>
                           <div className='dropdown'>
                              <button
                                 className='btn btn-secondary dropdown-toggle'
                                 type='button'
                                 data-bs-toggle='dropdown'
                                 aria-expanded='false'>
                                 {filteredDepartment ? filteredDepartment : 'Department'}
                              </button>

                              <ul className='dropdown-menu'>
                                 {department.map(d => (
                                    <li
                                       onClick={
                                          e => setFilteredDepartment(e.target.innerHTML)
                                          // handleClear()
                                       }>
                                       <div className='dropdown-item'>{d.Value}</div>
                                    </li>
                                 ))}
                              </ul>
                           </div>
                        </div>

                        {/* -------------------work type-------------------- */}

                        <div className='work-position-top-box work-position-top-box1'>
                           <div className='dropdown'>
                              <button
                                 className='btn btn-secondary dropdown-toggle'
                                 type='button'
                                 data-bs-toggle='dropdown'
                                 aria-expanded='false'>
                                 {filteredWorkType ? filteredWorkType : 'Work Type'}
                              </button>

                              <ul className='dropdown-menu'>
                                 {workType.map(w => (
                                    <li onClick={e => setFilteredWorkType(e.target.innerHTML)}>
                                       <div className='dropdown-item'>{w.Value}</div>
                                    </li>
                                 ))}
                              </ul>
                           </div>
                        </div>

                        {/* -------------------location-------------------- */}

                        <div className='work-position-top-box work-position-top-box1'>
                           <div className='dropdown'>
                              <button
                                 className='btn btn-secondary dropdown-toggle'
                                 type='button'
                                 data-bs-toggle='dropdown'
                                 aria-expanded='false'>
                                 {filteredLocation ? filteredLocation : 'Location'}
                              </button>

                              <ul className='dropdown-menu'>
                                 {location.map(l => (
                                    <li onClick={e => setFilteredLocation(e.target.innerHTML)}>
                                       <div className='dropdown-item'>{l.Value}</div>
                                    </li>
                                 ))}
                              </ul>
                           </div>
                        </div>

                        {/* -------------------Remote only check box-------------------- */}

                        <div className='work-position-top-box work-position-top-box2'>
                           <label className='work-position-top-box work-position-top-box2'>
                              <input
                                 type='checkbox'
                                 className='checkbox'
                                 onChange={e => {
                                    setIsRemote(e.target.checked);
                                 }}
                              />
                              Remote
                           </label>
                        </div>

                        {/* -------------------Clear All-------------------- */}

                        <div
                           className='work-position-top-box work-position-top-box2'
                           onClick={handleClear}>
                           <p className='clearAllText'>Clear all</p>
                        </div>
                     </div>
                     {/* -----------Job List-------------------- */}

                     {error === 'Unexpected end of JSON input' ? (
                        <div className='work-position-bottom-box'>
                           <h3 style={{ fontWeight: '400', margin: 'auto' }}>No jobs found.</h3>
                        </div>
                     ) : (
                        jobList.map(j => (
                           <div className='work-position-bottom-box'>
                              <div className='text'>
                                 <h4>
                                    {/* {j.Department}{' '} */}
                                    <span>
                                       {''}
                                       {j.Jobdisc}
                                    </span>
                                 </h4>
                                 <div className='text-bottom'>
                                    <h5>
                                       {j.is_remote ? 'Remote, ' : ''} {j.Location}
                                    </h5>
                                    <h5>{j.Worktype}</h5>
                                 </div>
                              </div>
                              <div className='btn-xyz'>
                                 <a href={j.Joburl} target='_blank' rel='noreferrer'>
                                    <button className='work-position-button'>Apply</button>
                                 </a>
                              </div>
                           </div>
                        ))
                     )}
                  </div>
               </div>
            </section>
         </div>
      </>
   );
};

export default Partnership;
