import './socialProof.scss';
// import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
// import required modules
import { Pagination, Autoplay } from 'swiper';

const SocialProof = () => {
   return (
      <section className='social-proof-section'>
         <div className='social-proof'>
            <Swiper
               spaceBetween={30}
               loop={true}
               pagination={{
                  clickable: true,
               }}
               modules={[Pagination, Autoplay]}
               className='mySwiper'
               autoplay={{ delay: 3000 }}>
               <SwiperSlide>
                  <div className='testimonial_wrapper'>
                     <div className='social-proof-heading'>What our users say</div>
                     <div className='social-proof-text'>
                        “I teach math to students in Grades 8 and 9. I joined the community app and
                        discovered worksheets on it. After each lesson, I download a worksheet from
                        the app and give it to my students for extra practice. Very useful app”
                     </div>
                     <div className='social-proof-name'>Lalitha Devi</div>
                     {/* <div className='social-proof-location'>@UniversityofHyderabad</div> */}
                  </div>
               </SwiperSlide>
               <SwiperSlide>
                  <div className='testimonial_wrapper'>
                     <div className='social-proof-heading'>What our users say</div>
                     <div className='social-proof-text'>
                        “An amazing platform for interacting with like minded teachers. It is also
                        helping me plan my lessons and classes within short time”
                     </div>
                     <div className='social-proof-name'>Lavanya Kovvuru</div>
                     {/* <div className='social-proof-location'>@UniversityofHyderabad</div> */}
                  </div>
               </SwiperSlide>
               <SwiperSlide>
                  <div className='testimonial_wrapper'>
                     <div className='social-proof-heading'>What our users say</div>
                     <div className='social-proof-text'>
                        “Innerscore app is an amazing platform for teachers which exhibits different
                        venues to upgrade teaching skills in teachers. It makes teaching simple. It
                        has the involvement of so many activities which makes learning a fun based
                        rather than mugging up the notes. It encourages the students to be
                        independently analytical and develops the critical thinking skills with
                        their experiences.”
                     </div>
                     <div className='social-proof-name'>Janaki Venkatesh</div>
                     {/* <div className='social-proof-location'>@UniversityofHyderabad</div> */}
                  </div>
               </SwiperSlide>
            </Swiper>
            {/* 
        <div className="social-proof-text">
          “Teachers are the backbone of any country, the pillars upon which all
          aspirations are converted into realities”
        </div>
        <div className="social-proof-name">Dr. APJ Abdul Kalam</div>
        <div className="social-proof-location">@UniversityofHyderabad</div>
        <div className="circles">• • • •</div> */}
         </div>
      </section>
   );
};

export default SocialProof;
