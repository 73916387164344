import { React, useEffect } from 'react';
import '../styles/about.scss';
import team1 from '../assets/images/aboutUs/team1.png';
import team2 from '../assets/images/aboutUs/team2.png';
import team3 from '../assets/images/aboutUs/team3.png';
import team4 from '../assets/images/aboutUs/team4.png';
import aboutDesc from '../assets/images/aboutUs/aboutDesc.png';
import { FaLinkedinIn } from 'react-icons/fa';
// import { BsTwitter } from "react-icons/bs";
import CTA from '../sections/CTA/CTA';
import Heading from '../components/Heading/Heading';
import ReactGA from 'react-ga';
const AboutUs = () => {
   useEffect(() => {
      ReactGA.pageview(window.location.pathname);
   });
   const top = {
      heading: 'About Us',
      // paragraph:
      //    'A community where you and thousands of other teachers come together to make the world of teaching and learning…better',
   };

   return (
      <>
         <div className='about'>
            {/* ---------------- heading container --------------- */}
            <Heading heading={top.heading} paragraph={top.paragraph} />

            {/* ------------------ about desc ------------------ */}
            {/* <div className='about-middle-container'>
               <div className='about-middle-box'>
                  <div className='about-sub-heading'>Mission</div>
                  <div style={{ color: '#C09BD1' }} className='about-box-para'>
                     1 Million Active Users by 2025
                  </div>
               </div>

               <div className='vl'></div>

               <div className='about-middle-box'>
                  <div className='about-sub-heading'>Values</div>
                  <div style={{ color: '#F5C36E' }} className='about-box-para'>
                     Diversity Empathy Inclusion
                  </div>
               </div>

               <div className='vl'></div>

               <div className='about-middle-box'>
                  <div className='about-sub-heading'>Social Impact</div>
                  <div style={{ color: '#4389A9' }} className='about-box-para'>
                     1 Million underprivileged active users by 2025
                  </div>
               </div>
            </div> */}

            {/* -------------------- about team ----------------- */}
            <div className='about-team'>
               <div className='about-heading2'>Meet Our Team</div>
               {/* <div className='about-sub-heading'>
                  A community where you and thousands of other teachers come together to make the
                  world of teaching and learning…better
               </div> */}
               <div className='about-team-box'>
                  <div className='about-team-boxes'>
                     <img src={team1} alt='pic' />
                     <h3>Naga Tummala</h3>
                     <h6>Co-Founder, CPO & CEO</h6>
                     <div className='about-social-media'>
                        <div className='about-social-media-box'>
                           <a
                              href='https://www.linkedin.com/in/tummala-naga-prasad'
                              target='_blank'
                              rel='noreferrer'>
                              <FaLinkedinIn />
                           </a>
                        </div>
                        {/* <div className='about-social-media-box'>
                           <FaFacebookF />
                        </div>
                        <div className='about-social-media-box'>
                           <BsTwitter />
                        </div> */}
                     </div>
                  </div>

                  <div className='about-team-boxes'>
                     <img src={team2} alt='pic' />
                     <h3>Raj Yarlagadda</h3>
                     <h6>Co-Founder, Chief of Strategy & Business</h6>
                     <div className='about-social-media'>
                        <div className='about-social-media-box'>
                           <a
                              href=' https://www.linkedin.com/in/raj-yarlagadda-7ba39031'
                              target='_blank'>
                              <FaLinkedinIn />
                           </a>
                        </div>
                        {/* <div className='about-social-media-box'>
                           <FaFacebookF />
                        </div>
                        <div className='about-social-media-box'>
                           <BsTwitter />
                        </div> */}
                     </div>
                  </div>

                  <div className='about-team-boxes'>
                     <img src={team3} alt='pic' />
                     <h3>Sharad Nandwani</h3>
                     <h6>Sr. Vice President - Product</h6>
                     <div className='about-social-media'>
                        <div className='about-social-media-box'>
                           <a
                              href='https://www.linkedin.com/in/sharadn'
                              target='_blank'
                              rel='noreferrer'>
                              <FaLinkedinIn />
                           </a>
                        </div>
                        {/* <div className='about-social-media-box'>
                           <FaFacebookF />
                        </div>
                        <div className='about-social-media-box'>
                           <BsTwitter />
                        </div> */}
                     </div>
                  </div>

                  <div className='about-team-boxes'>
                     <img src={team4} alt='pic' />
                     <h3>Ram Chinta</h3>
                     <h6>Chief Technology Officer (CTO)</h6>
                     <div className='about-social-media'>
                        <div className='about-social-media-box'>
                           <a
                              href='https://www.linkedin.com/in/ramchinta'
                              target='_blank'
                              rel='noreferrer'>
                              <FaLinkedinIn />
                           </a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            {/* --------------------- about desc who we are -------------- */}
            <div className='about-desc'>
               <div className='about-desc-left'>
                  <img src={aboutDesc} alt='' />
               </div>
               <div className='about-desc-right'>
                  <div className='about-heading2'>Who We Are</div>
                  <div className='about-sub-heading2'>
                     At Innerscore, we believe every individual is a leader in their own path. We
                     offer a platform where you can create the best work of your life.
                     <br />
                     <br />
                     We are always on a look out for people who have a passion for their work and
                     are ready to explore and experiment.
                  </div>
               </div>
            </div>
         </div>

         <CTA />
      </>
   );
};

export default AboutUs;
