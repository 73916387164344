import './dailyTip.scss';
import first from '../../../assets/images/first.png';
import second from '../../../assets/images/second.png';
import third from '../../../assets/images/third.png';
import fourth from '../../../assets/images/fourth.png';
import fifth from '../../../assets/images/fifth.png';
import ButtonOutlined from '../../../components/button/ButtonOutlined';
import { useEffect, useState } from 'react';
import { isVisible } from '../parallax.js';

const DailyTip = () => {
   const [number, setNumber] = useState(first);

   const handleScroll = () => {
      const features_1 = document.getElementById('first');
      const first_one = isVisible(features_1);
      const features_2 = document.getElementById('second');
      const second_one = isVisible(features_2);
      const features_3 = document.getElementById('third');
      const third_one = isVisible(features_3);
      const features_4 = document.getElementById('fourth');
      const fourth_one = isVisible(features_4);
      const features_5 = document.getElementById('fifth');
      const fifth_one = isVisible(features_5);

      if (first_one) {
         setNumber(first);
      } else if (second_one) {
         setNumber(second);
      } else if (third_one) {
         setNumber(third);
      } else if (fourth_one) {
         setNumber(fourth);
      } else if (fifth_one) {
         setNumber(fifth);
      }
   };
   useEffect(() => {
      window.addEventListener('scroll', handleScroll);
   }, [number]);

   return (
      <section className='daily-tips-section'>
         <div className='daily-tips-container'>
            <div className='daily-tips-img'>
               <div className='sticky'>
                  <img src={`${number}`} alt='Daily Tips ' />
               </div>
            </div>
            <div className='daily-tips-text'>
               <div className='text'>
                  <h1>Daily Engagement Tips</h1>
                  <p id='first'>Tips to engage students and parents and connect better with them</p>
                  <a
                     href={`https://play.google.com/store/apps/details?id=com.innerscore&referrer=utm_source%3DWebsite%26utm_medium%3DBanner%26utm_campaign%3DGetTipsNow`}
                     target='_blank'
                     rel='noreferrer'>
                     <ButtonOutlined text={'View Tips Now'} className='button-outlined' />
                  </a>
               </div>
               <div className='text'>
                  <h1>Customisable Worksheets & Question Paper</h1>
                  <p id='second'>Customisable worksheets and question paper to save time</p>
                  <a
                     href={`https://play.google.com/store/apps/details?id=com.innerscore&referrer=utm_source%3DWebsite%26utm_medium%3DBanner%26utm_campaign%3DWorksheets`}
                     target='_blank'
                     rel='noreferrer'>
                     <ButtonOutlined
                        text={'Get customisable worksheets'}
                        className='button-outlined'
                     />
                  </a>
               </div>
               <div className='text'>
                  <h1>Teacher Community</h1>
                  <p id='third'>
                     A community where teachers can share their best practices and learn from each
                     other
                  </p>
                  <a
                     href={`https://play.google.com/store/apps/details?id=com.innerscore&referrer=utm_source%3DWebsite%26utm_medium%3DBanner%26utm_campaign%3DCommunity`}
                     target='_blank'
                     rel='noreferrer'>
                     <ButtonOutlined text={'Join the community'} className='button-outlined' />
                  </a>
               </div>
               <div className='text'>
                  <h1>Curated Content </h1>
                  <p id='fourth'>
                     Curated content in the form of articles, videos and podcasts for teachers to
                     learn and up skill effortlessly
                  </p>
                  <a
                     href={`https://play.google.com/store/apps/details?id=com.innerscore&referrer=utm_source%3DWebsite%26utm_medium%3DBanner%26utm_campaign%3DLearn`}
                     target='_blank'
                     rel='noreferrer'>
                     <ButtonOutlined text={'Discover Now'} className='button-outlined' />
                  </a>
               </div>
               <div className='text'>
                  <h1>Chapter Plans & Teaching Plans</h1>
                  <p id='fifth'>
                     Personalised chapter plans and teaching plans for teachers to be more effective
                     in the classroom
                  </p>
                  <a
                     href={`https://play.google.com/store/apps/details?id=com.innerscore&referrer=utm_source%3DWebsite%26utm_medium%3DBanner%26utm_campaign%3DWorksheets`}
                     target='_blank'
                     rel='noreferrer'>
                     <ButtonOutlined text={'View Now'} className='button-outlined' />
                  </a>
               </div>
            </div>
         </div>

         <div className='daily_tips_md'>
            <div className='daily-tips-container_md'>
               <div className='daily-tips-img'>
                  <img src={first} alt='Daily Tips ' />
               </div>
               <div className='daily-tips-text'>
                  <h1>Daily Engagement Tips</h1>
                  <p>Tips to engage students and parents and connect better with them</p>
                  <a
                     href={`https://play.google.com/store/apps/details?id=com.innerscore&referrer=utm_source%3DWebsite%26utm_medium%3DBanner%26utm_campaign%3DGetTipsNow`}
                     target='_blank'
                     rel='noreferrer'>
                     <ButtonOutlined text={'View Tips Now'} className='button-outlined' />
                  </a>
               </div>
            </div>
            <div className='daily-tips-container_md'>
               <div className='daily-tips-img'>
                  <img src={second} alt='Daily Tips ' />
               </div>
               <div className='daily-tips-text'>
                  <h1>Customisable Worksheets & Question Paper</h1>
                  <p>Customisable worksheets and question paper to save time</p>
                  <a
                     href={`https://play.google.com/store/apps/details?id=com.innerscore&referrer=utm_source%3DWebsite%26utm_medium%3DBanner%26utm_campaign%3DWorksheets`}
                     target='_blank'
                     rel='noreferrer'>
                     <ButtonOutlined
                        text={'Get customisable worksheets'}
                        className='button-outlined'
                     />
                  </a>
               </div>
            </div>
            <div className='daily-tips-container_md'>
               <div className='daily-tips-img'>
                  <img src={third} alt='Daily Tips ' />
               </div>
               <div className='daily-tips-text'>
                  <h1>Teacher Community</h1>
                  <p>
                     A community where teachers can share their best practices and learn from each
                     other
                  </p>
                  <a
                     href={`https://play.google.com/store/apps/details?id=com.innerscore&referrer=utm_source%3DWebsite%26utm_medium%3DBanner%26utm_campaign%3DCommunity`}
                     target='_blank'
                     rel='noreferrer'>
                     <ButtonOutlined text={'Join the community'} className='button-outlined' />
                  </a>
               </div>
            </div>
            <div className='daily-tips-container_md'>
               <div className='daily-tips-img'>
                  <img src={fourth} alt='Daily Tips ' />
               </div>
               <div className='daily-tips-text'>
                  <h1>Curated Content</h1>
                  <p>
                     Curated content in the form of articles, videos and podcasts for teachers to
                     learn and up skill effortlessly
                  </p>
                  <a
                     href={`https://play.google.com/store/apps/details?id=com.innerscore&referrer=utm_source%3DWebsite%26utm_medium%3DBanner%26utm_campaign%3DLearn`}
                     target='_blank'
                     rel='noreferrer'>
                     <ButtonOutlined text={'Discover Now'} className='button-outlined' />
                  </a>
               </div>
            </div>
            <div className='daily-tips-container_md'>
               <div className='daily-tips-img'>
                  <img src={fifth} alt='Daily Tips ' />
               </div>
               <div className='daily-tips-text'>
                  <h1>Chapter Plans & Teaching Plans</h1>
                  <p>
                     Personalised chapter plans and teaching plans for teachers to be more effective
                     in the classroom
                  </p>
                  <a
                     href={`https://play.google.com/store/apps/details?id=com.innerscore&referrer=utm_source%3DWebsite%26utm_medium%3DBanner%26utm_campaign%3DWorksheets`}
                     target='_blank'
                     rel='noreferrer'>
                     <ButtonOutlined text={'View Now'} className='button-outlined' />
                  </a>
               </div>
            </div>
         </div>
      </section>
   );
};

export default DailyTip;
