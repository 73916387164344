import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import { BsInstagram, BsTwitter, BsYoutube, BsMedium } from 'react-icons/bs';
import { GiHamburgerMenu } from 'react-icons/gi';

import './navbar.scss';
import logo from '../../assets/Logo/Logo_Big/logo_big.png';
import Button from './../button/Button';
import cross from '../../assets/images/cross.png';
import navFingerPrint from '../../assets/images/work-with-us/fingerprint.png';

const Navbar = () => {
   const [isMobile, setIsMobile] = useState(false);
   const [colorChange, setColorchange] = useState(false);
   const [onStudent, setOnStudent] = useState(false);
   const [onParent, setOnParent] = useState(false);
   // const [buttonClass, setButtonClass] = useState('navDownloadButton');

   const changeNavbarColor = () => {
      if (window.innerWidth > 768) {
         changeDesktopNavCol();
      } else {
         changeMobNavCol();
      }
   };
   const changeDesktopNavCol = () => {
      if (window.scrollY >= 70) {
         setColorchange(true);
      } else if (window.scrollY <= 70) {
         setColorchange(false);
      }
   };
   const changeMobNavCol = () => {
      if (window.scrollY >= 20) {
         setColorchange(true);
      } else if (window.scrollY <= 20) {
         setColorchange(false);
      }
   };

   const location = useLocation();
   useEffect(() => {
      if (location.pathname === '/students') {
         setOnStudent(true);
         setOnParent(false);
      } else if (location.pathname === '/parents') {
         setOnStudent(false);
         setOnParent(true);
      } else {
         setOnStudent(false);
         setOnParent(false);
      }
   }, [location]);

   window.addEventListener('scroll', changeNavbarColor);

   return (
      <>
         <nav className={`navbar ${colorChange ? `navbarColorChange` : ''}`}>
            <div className='nav-inner'>
               {/* logo and navlinks */}
               <div className='navLeft'>
                  <div className='div_logo'>
                     <Link style={{ color: 'black', textDecoration: 'none' }} to='/'>
                        <img className='navLogo' src={logo} alt='' />
                     </Link>
                  </div>
                  <div
                     onClick={() => setIsMobile(false)}
                     className={`navLinkContainer ${isMobile ? `navLinkContainerMobile` : ''}`}>
                     <div className='nav-fingerprint'>
                        <img src={navFingerPrint} alt='' />
                     </div>

                     <div className='crossButton'>
                        <img src={cross} alt='' />
                     </div>
                     <div className='navLink'>
                        <Link
                           style={{
                              color: 'black',
                              textDecoration: 'none',
                              display: isMobile ? '' : 'none',
                           }}
                           to='/'>
                           Home
                        </Link>
                     </div>
                     <div className='navLink'>
                        <Link style={{ color: 'black', textDecoration: 'none' }} to='/about'>
                           About Us
                        </Link>
                     </div>
                     <div className={'navLink'}>
                        <Link
                           style={{ color: 'black', textDecoration: 'none' }}
                           to={`${onStudent ? '/' : '/students'}`}>
                           {`${onStudent ? 'Teacher' : 'Students'}`}
                        </Link>
                     </div>
                     <div className='navLink'>
                        <Link
                           style={{ color: 'black', textDecoration: 'none' }}
                           to={`${onParent ? '/' : '/parents'}`}>
                           {`${onParent ? 'Teacher' : 'Parents'}`}
                        </Link>
                     </div>
                     <div className='navLink'>
                        <Link style={{ color: 'black', textDecoration: 'none' }} to='/partnership'>
                           Partnership
                        </Link>
                     </div>
                     <div className='navLink'>
                        <Link style={{ color: 'black', textDecoration: 'none' }} to='/work-with-us'>
                           Work with Us
                        </Link>
                     </div>
                     <div className='navLink'>
                        <Link style={{ color: 'black', textDecoration: 'none' }} to='/contact-us'>
                           Contact Us
                        </Link>
                     </div>
                     <button className='home-download-button' href='#'>
                        <a
                           href={`https://play.google.com/store/apps/details?id=com.innerscore&referrer=utm_source%3DWebsite%26utm_medium%3DBanner%26utm_campaign%3DDownloadApp`}
                           target='_blank'
                           rel='noreferrer'>
                           {' '}
                        </a>
                        Download App
                     </button>
                     <div className='home-detail-text'>
                        <h4>
                           <a
                              href='tel:+917075780899'
                              style={{ color: 'black', textDecoration: 'none' }}>
                              +91-7075780899
                           </a>
                        </h4>
                        <h4>
                           <a
                              href='mailto: info@innerscore.com'
                              style={{ color: 'black', textDecoration: 'none' }}>
                              info@innerscore.com
                           </a>
                        </h4>
                     </div>

                     <div className='footer-social-media'>
                        <div className='footer-social-media-box'>
                           <a
                              href='https://www.instagram.com/innerscore01/?hl=en&utm_source=Website&utm_medium=CTA&utm_campaign=ISWebsite_Tracking'
                              target='_blank'
                              rel='noreferrer'
                              style={{
                                 color: '#241D1B',
                                 textDecoration: 'none',
                                 transform: 'translateY(6%)',
                              }}>
                              <BsInstagram />
                           </a>
                        </div>
                        <div className='footer-social-media-box'>
                           <a
                              href='https://www.facebook.com/profile.php?id=100041345365848&utm_source=Website&utm_medium=CTA&utm_campaign=ISWebsite_Tracking'
                              target='_blank'
                              rel='noreferrer'
                              style={{
                                 color: '#241D1B',
                                 textDecoration: 'none',
                                 transform: 'translateY(6%)',
                              }}>
                              <FaFacebookF />
                           </a>
                        </div>
                        <div className='footer-social-media-box'>
                           <a
                              href='https://twitter.com/innerscore?lang=en&utm_source=Website&utm_medium=CTA&utm_campaign=ISWebsite_Tracking
                              '
                              target='_blank'
                              rel='noreferrer'
                              style={{
                                 color: '#241D1B',
                                 textDecoration: 'none',
                                 transform: 'translateY(6%)',
                              }}>
                              <BsTwitter />
                           </a>
                        </div>
                        <div className='footer-social-media-box'>
                           <a
                              href='https://www.linkedin.com/company/innerscore/?originalSubdomain=in&utm_source=Website&utm_medium=CTA&utm_campaign=ISWebsite_Tracking'
                              target='_blank'
                              rel='noreferrer'
                              style={{
                                 color: '#241D1B',
                                 textDecoration: 'none',
                                 transform: 'translateY(6%)',
                              }}>
                              <FaLinkedinIn />
                           </a>
                        </div>
                        <div className='footer-social-media-box'>
                           <a
                              href='https://www.youtube.com/channel/UCRvUTObfcqsHuMupkiV4F0A?utm_source=Website&utm_medium=CTA&utm_campaign=ISWebsite_Tracking'
                              target='_blank'
                              rel='noreferrer'
                              style={{
                                 color: '#241D1B',
                                 textDecoration: 'none',
                                 transform: 'translateY(6%)',
                              }}>
                              <BsYoutube />
                           </a>
                        </div>
                        <div className='footer-social-media-box'>
                           <a
                              href='https://medium.com/@innerscore?utm_source=Website&utm_medium=CTA&utm_campaign=ISWebsite_Tracking'
                              target='_blank'
                              rel='noreferrer'
                              style={{
                                 color: '#241D1B',
                                 textDecoration: 'none',
                                 transform: 'translateY(6%)',
                              }}>
                              <BsMedium />
                           </a>
                        </div>
                     </div>
                  </div>
               </div>

               {/* button and hamburger */}
               <div className='navRight'>
                  <div className='navDownloadButton'>
                     <Button />
                  </div>

                  {/* hamburger */}
                  <div onClick={() => setIsMobile(!isMobile)} className='mobileMenuIcon'>
                     <GiHamburgerMenu />
                  </div>
               </div>
            </div>
         </nav>
      </>
   );
};

export default Navbar;
