/* eslint-disable jsx-a11y/anchor-is-valid */
import { React, useEffect } from 'react';
import '../styles/parents.scss';
import parentbanner from '../assets/images/parents/image.png';
import parent1 from '../assets/images/parents/one.png';
import parent2 from '../assets/images/parents/two.png';
import parent3 from '../assets/images/parents/three.png';
// import Button from "../components/button/Button";
import ReactGA from 'react-ga';
const Parents = () => {
   useEffect(() => {
      ReactGA.pageview(window.location.pathname);
   });
   return (
      <>
         {/* ----------------- section 1 ------------------- */}

         <section className='parent-section'>
            <div className='parent-container'>
               {/* left container */}

               <div className='parent-container-left'>
                  {/* <div className='hash-tag'>#RISEHIGHER</div> */}
                  <div className='main-text'>Help your children stay focus on learning</div>
                  <div className='helper-text'>
                     Get access to necessary frameworks, tools and insights to keep tab on your
                     child’s learning journey.
                  </div>
                  <div className='button'>
                     <button className='student-button'>Coming Soon</button>
                  </div>
               </div>

               {/* right-cotainer */}
               <div className='parent-container-right'>
                  <img id='parent_image' src={parentbanner} alt='parent' />
               </div>
            </div>
         </section>
         <div className='extra_space'></div>

         {/* ----------------- section 2 ------------------- */}

         {/* 2.1 */}

         <div className='parent-top'>
            <div className='parent-top-left'>
               <img src={parent1} alt='' />
            </div>
            <div className='parent-top-right'>
               <h2>Know Your Child</h2>
               <p>
                  Innerscore provides parents with many slices of understanding of their child, that
                  are both quantitative and qualitative. It also provides research-based AI tools
                  for parents to record and infer ever changing personality of their child to take
                  timely corrective steps, if needed. It also provides a detailed portfolio of the
                  child which will be a treasure for the parents.
               </p>
               <button className='parent-button'>
                  <a href='#'>Coming Soon</a>
               </button>
            </div>
         </div>

         {/* 2.2 */}
         <div className='parent-bottom'>
            <div className='parent-bottom-left'>
               <h2>Help Your Child</h2>
               <p>
                  There are many ways parents can help children stay focused on learning. Innerscore
                  provides framework and content for active involvement of parents in their child’s
                  learning without much effort and at the same time immensely helping their child.
               </p>
               <button className='parent-button'>
                  <a href='#'>Coming Soon</a>
               </button>
            </div>
            <div className='parent-bottom-right'>
               <img src={parent2} alt='' />
            </div>
         </div>

         {/* 2.3 */}
         <div className='parent-top'>
            <div className='parent-top-left'>
               <img src={parent3} alt='' />
            </div>
            <div className='parent-top-right'>
               <h2>Stay Ahead</h2>
               <p>
                  Parenting is both science and art. Parents do not need to search all around the
                  web to find answers to the most important questions regarding their child’s growth
                  and progress. With Innerscore parents have access to content which can help to
                  steer the growth of their child. With just a few clicks, a parent can master the
                  art and become a Proud Parent.
               </p>
               <button className='parent-button'>
                  <a href='#'>Coming Soon</a>
               </button>
            </div>
         </div>
      </>
   );
};

export default Parents;
