import React from "react";
import './heading.scss';

const Heading = ({heading,paragraph}) => {
  return (
    <>
    
      <div className="top-heading">
      <h1>{heading} </h1>
      </div>
    
      <div className="top-para">
        {paragraph}
      </div>
    </>
  );
};

export default Heading;
