import { React, useEffect } from 'react';
import Parallex from '../sections/features/parallex';
import Hero from '../sections/hero/hero';
// import Navbar from "../components/Navbar/Navbar";
// import Footer from "../components/footer/footer";
// import DailyTip from '../sections/features/dailyTips/dailyTip';
import FeaturesHead from '../sections/featuresHead/featuresHead';
import SocialProof from '../sections/socialProof/socialProof';
import CTA from '../sections/CTA/CTA';
import ReactGA from 'react-ga';

const HomePage = () => {
   useEffect(() => {
      ReactGA.pageview(window.location.pathname);
   });
   return (
      <>
         {/* <Navbar/> */}
         <Hero />
         <FeaturesHead />
         {/* <DailyTip /> */}
         <Parallex />
         <SocialProof />
         <CTA />
         {/* <Footer/> */}
      </>
   );
};

export default HomePage;
