export const isVisible = (ele) => {
    const rect = ele.getBoundingClientRect();
    const vWidth = window.innerWidth || document.documentelement.clientWidth;
    const vHeight = window.innerHeight || document.documentelement.clientHeight;
    const efp = function (x, y) { return document.elementFromPoint(x, y); };
    // Return false if it's not in the viewport
    if (rect.right < 0 || rect.bottom < 0
              || rect.left > vWidth || rect.top > vHeight) { return false; }
    // Return true if any of its four corners are visible
    return (
      ele.contains(efp(rect.left, rect.top))
        || ele.contains(efp(rect.right, rect.top))
        || ele.contains(efp(rect.right, rect.bottom))
        || ele.contains(efp(rect.left, rect.bottom))
    );
  };