import { React, useEffect } from 'react';
import '../styles/students.scss';
import studentbanner from '../assets/images/students/studentbanner.png';
import bannerBottom from '../assets/images/students/student-banner-bottom.png';
import student1 from '../assets/images/students/student1.png';
import student2 from '../assets/images/students/student2.png';
import student3 from '../assets/images/students/student3.png';
// import Button from "../components/button/Button";
import ReactGA from 'react-ga';
const Students = () => {
   useEffect(() => {
      ReactGA.pageview(window.location.pathname);
   });
   return (
      <>
         {/* ----------------- section 1 ------------------- */}

         <section className='student-section'>
            <div className='student-container'>
               {/* left container */}

               <div className='student-container-left'>
                  {/* <div className='hash-tag'>#RISEHIGHER</div>  Removed it is for Only teachers*/}
                  <div className='main-text'>Joyful learning experience for all students</div>
                  <div className='helper-text'>
                     without any academic gaps in the shortest time possible.
                  </div>
                  <div className='button'>
                     <a href='https://student.innerscore.com' target='_blank' rel='noreferrer'>
                        <button className='student-button'>Join Us Now</button>
                     </a>
                  </div>
               </div>

               {/* right-cotainer */}
               <div className='student-container-right'>
                  <img id='student_image' src={studentbanner} alt='student' />
               </div>
            </div>
         </section>
         <div className='extra_space'></div>

         {/* ----------------- section 2 ------------------- */}

         {/* 2.1 */}
         <div className='student-top'>
            <div className='student-top-left'>
               <img src={student1} alt='' />
            </div>
            <div className='student-top-right'>
               <h2>Golden Window</h2>
               <p>
                  Golden Window is for students of Grade 6-8. The course is organized into three
                  levels — Foundational, Proficiency and Advanced. All the course pre-requisites are
                  part of the Foundational module. Grade-level requirements are covered in
                  Proficiency module. The Advanced module takes students to even higher levels of
                  knowledge, which will accrue them future benefits such as success at Olympiads,
                  ease in higher grades, prep for competitive exams, etc. The intelligent technology
                  of Innerscore maps the closest learning path to the highest proficiency level in
                  the shortest time, based on students’ prior knowledge, comprehension and pace of
                  learning
               </p>
               <button className='parent-button'>
                  <a href='https://student.innerscore.com' target='_blank' rel='noreferrer'>
                     Join us now
                  </a>
               </button>
            </div>
         </div>

         {/* 2.2 */}
         <div className='student-bottom'>
            <div className='student-bottom-left'>
               <h2>Take-Off Period</h2>
               <p>
                  Take-Off Period is for students of Grade 9-12. The course is organized into three
                  levels - Foundational, Proficiency and Advanced. All the course pre-requisites up
                  till one grade level lower are part of the Foundational module. Grade-level
                  requirements are covered in the Proficiency module. The Advanced module takes
                  students to even higher levels of knowledge, which will accrue them future
                  benefits such as success in competitive exams, being awarded a Diploma, receiving
                  college-level course waivers, etc. The intelligent technology of Innerscore maps
                  the closest learning path to the highest proficiency level in the shortest time,
                  based on students' prior knowledge, comprehension and pace of learning. An
                  Al-based smart teacher is always available to clarify doubts and a real designated
                  teacher also supervises students* learning.
               </p>
               <button className='parent-button'>
                  <a href='https://student.innerscore.com' target='_blank' rel='noreferrer'>
                     Join us now
                  </a>
               </button>
            </div>
            <div className='student-bottom-right'>
               <img src={student2} alt='' />
            </div>
         </div>

         {/* 2.3 */}
         <div className='student-top'>
            <div className='student-top-left'>
               <img src={student3} alt='' />
            </div>
            <div className='student-top-right'>
               <h2>Open Learning</h2>
               <p>
                  Open Learning is most suited for those who wish to explore their interests beyond
                  the curriculum and grade-level to which they belong. Students can learn any
                  subject starting at any level, and can learn up to any level, with Open Learning.
                  Each subject is classified into three levels — Novice, Competent and Expert.
                  Students who are a part of the Golden Window or the Take-Off Period can also
                  pursue subjects of interest as part of Open Learning. Since there is no pressure
                  to follow a timeline, students can learn subjects of interest with lot more fun
                  and excitement.
               </p>
               <button className='parent-button'>
                  <a href='https://student.innerscore.com' target='_blank' rel='noreferrer'>
                     Join us now
                  </a>
               </button>
            </div>
         </div>

         {/* ----------------- section 3 ------------------- */}

         <section className='student-cta-section'>
            <div className='student-cta-container'>
               <div className='student-cta-text'>
                  <div className='student-cta-heading'>
                     <h3>Joyful learning experience for all students</h3>
                  </div>
                  <div className='student-cta-sub-heading'>
                     without any academic gaps in the shortest time possible.
                  </div>
                  <button className='button-outline student-cta-btn'>Join us now</button>
               </div>

               <div className='student-cta-img'>
                  <img src={bannerBottom} alt='Daily Tips ' />
               </div>
            </div>
         </section>
      </>
   );
};

export default Students;
